.rootWrap {
    position: relative;
}

.actionButtonsWrap {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
}