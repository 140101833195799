@import "../../../styles/stylesVariables.scss";

// ------ filterby ------ //
%filterBy {
    display: flex;
    padding-left: 0;
}

.filterByPatient {
    @extend %filterBy;
    max-width: 13em;
}
.filterByInput {
    @extend %filterBy;
    max-width: 8em;
}
.filterByInputPortrait {
    @extend %filterBy;
    max-width: 5em;
}
.filterByInputLandscape {
    @extend %filterBy;
    max-width: 6em;
}

.filterByDropdown {
    @extend %filterBy;
    max-width: 10em;
}
.filterByDropdownPortrait {
    @extend %filterBy;
    max-width: 7em;
}
.filterByDropdownLandscape {
    @extend %filterBy;
    max-width: 7em;
}
// ---------------------------------------- //

.dataScrollerContent {
    margin-top: calc(#{$subMenu} + #{$mainSearchPanel}) !important;
}

.dataScrollerContentWithRemoveBtn {
    margin-top: calc(#{$subMenu} + #{$mainSearchPanel} + #{$removeFiltersForeMoreRecordsHeight}) !important;
}