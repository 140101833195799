@import '../../../styles/stylesVariables.scss';

$desktopFormContainerMaxWidth: 525px;
$desktopFormContainerTabletWidth: 390px;
$contentPadding: $contentOutsidePaddings-Y 0px;

.form_with_catalogs_wrap {
  height: calc(100vh - #{$mainHeader});
}

.form_with_catalogs_container {
  width: 100%;
  max-width: 1900px;
  padding: 0px 25px;
  column-gap: 15px;
}

.left_card_desktop_form_container {
  max-width: $desktopFormContainerMaxWidth;
  min-width: $desktopFormContainerMaxWidth;
  width: 100%;
  height: 100%;
  padding: $contentPadding;
}

.left_card_content {
  height: 100%;

  .p-card-body {
    height: 100%;
  }

  .p-card-content {
    height: calc(100% - 45px);
    padding-top: 0;
  }
}

.left_card_mobile_form_container {
  display: none;
}

.left_card_scroll_panel {
  width: 100%;
  height: 100%;

  .p-scrollpanel-content {
    padding-right: 10px;
    padding-bottom: 35px !important;
  }

  .p-scrollpanel-wrapper {
    border-right: 12px solid transparent;
  }
}

.left_card_action_buttons {
  padding-right: 15px;
}

.right_card_desktop_catalog_container {
  width: calc(100% - #{$desktopFormContainerMaxWidth}); // DO NOT REMOVE CALCULATION!
  height: 100%;
  padding: $contentPadding;
}

.right_card_catalogs_card {
  height: 100%;

  .catalogs_card_header {
    row-gap: 2.5rem;
    column-gap: 2.5rem;
  }

  .p-card-body {
    height: 100%;
  }

  .p-card-content {
    height: 100%;
  }
}

// Mobile only in portrait mode and low screen resolution
@media (min-width: 200px) and (max-width: 388px) {
  .left_card_mobile_form_container {
    display: block !important;
    max-width: 360px !important;
    width: 100%;
  }
}

// Mobile only in portrait mode
@media (min-width: 389px) and (max-width: 639px) {
  .left_card_mobile_form_container {
    display: block;
    max-width: 390px !important;
    width: 100%;
  }
}

// Mobile in landscape mode
@media (min-width: 200px) and (max-width: 959px) {
  .left_card_mobile_form_container {
    display: block !important;
    width: 100%;
  }
}

// Tablets only
@media (min-width: 640px) and (max-width: 1023px) {
  .left_card_mobile_form_container {
    display: block !important;
    min-width: $desktopFormContainerMaxWidth;
    max-width: $desktopFormContainerMaxWidth;
    width: 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1310px) {
  .form_with_catalogs_container {
    padding: 0px 15px !important;
  }

  .left_card_desktop_form_container {
    max-width: $desktopFormContainerTabletWidth;
    min-width: $desktopFormContainerTabletWidth;
  }

  .right_card_desktop_catalog_container {
    width: calc(100% - #{$desktopFormContainerTabletWidth}) !important;
  }
}

@media screen and (max-width: 1023px) {
  .form_with_catalogs_container {
    padding: 0px 10px !important;
  }

  .left_card_desktop_form_container {
    display: none !important;
  }

  .right_card_desktop_catalog_container {
    display: none;
  }

  .left_card_action_buttons {
    position: sticky !important;
    bottom: 0;
    background: #fff;
    padding: 15px 0px;
    z-index: 1;
  }
}
