.memos-table-wrap {
  height: calc(100vh - 90px);

  .memos-column-contet {
    padding: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .memos-table-wrap {
    height: calc(100vh - 130px);
  }
}
