@import "../../styles/stylesVariables.scss";
@import "../../styles/layoutVariables.scss";
@import "../../../public/assets/sass/overrides/layout_variables";

%root {
    min-height: $chipsPanel;
    border-bottom: 1px solid $borderGray;
    background-color: #fff;
    z-index: 100;
}

.rootDesctop {
    position: sticky;
    top: calc(#{$mainHeaderMobile} + #{$subMenu} + #{$mainSearchPanel});
    @extend %root;
}

.rootOverlay {
    @extend %overlayRoot;
    position: fixed;
    top: calc(#{$mainHeader} + #{$subMenu} + #{$mainSearchPanel});
    @extend %root;
}

.rootStatic {
    @extend %staticRoot;
    position: fixed;
    top: calc(#{$mainHeader} + #{$subMenu} + #{$mainSearchPanel});
    @extend %root;
}

.rootMobile {
    width: 100% !important;
    // @extend %overlayRoot;
    position: fixed;
    top: calc(#{$mainHeader} + #{$subMenu} + #{$mainSearchPanel});
    @extend %root;
}

.rootMobileOnly {
    position: sticky;
    top: calc(#{$mainHeaderMobile} + #{$subMenu} + #{$mainSearchPanel});
    @extend %root;
}