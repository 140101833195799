.expanderButton {
    width: 2em;
}
.cm_top_td td {
    vertical-align: top;
    padding: 0.857em;
}
.cm_col_1em {
    outline: none !important;
}
.cm_col_2em {
    width: 2em;
    outline: none !important;
}
.cm_col_3em {
    width: 3em;
    outline: none !important;
}
.cm_col_4em {
    width: 4em;
    outline: none !important;
}
.cm_col_5em {
    width: 5em;
    outline: none !important;
}
.cm_col_6em {
    width: 6em;
    outline: none !important;
}
.cm_col_7em {
    width: 7em;
    outline: none !important;
}
.cm_col_8em {
    width: 8em;
    outline: none !important;
}
.cm_col_9em {
    width: 9em;
    outline: none !important;
}
.cm_col_10em {
    width: 10em;
    outline: none !important;
}
.cm_col_11em {
    width: 11em;
    outline: none !important;
}
.cm_col_12em {
    width: 12em;
    outline: none !important;
}
.cm_col_13em {
    width: 13em;
    outline: none !important;
}
.cm_col_14em {
    width: 14em;
    outline: none !important;
}
.cm_col_15em {
    width: 15em;
    outline: none !important;
}
.cm_col_16em {
    width: 16em;
    outline: none !important;
}
.cm_col_17em {
    width: 17em;
    outline: none !important;
}
.cm_col_18em {
    width: 18em;
    outline: none !important;
}
.cm_col_19em {
    width: 19em;
    outline: none !important;
}
.cm_col_20em {
    width: 20em;
    outline: none !important;
}
.cm_col_30em {
    width: 30em;
    outline: none !important;
}
.cm_col_40em {
    width: 40em;
    outline: none !important;
}
.cm_col_10 {
    width: 10%;
    outline: none !important;
}

.cm_col_20 {
    width: 20%;
    outline: none !important;
}

.cm_col_30 {
    width: 30%;
    outline: none !important;
}

.cm_col_26 {
    width: 26%;
    outline: none !important;
}

.cm_col_tablet {
    width: 61px !important;
    outline: none !important;
}

.actionColumn {
    text-align: center;
    // width: 6em;
    outline: none !important;
}
.moneyColumn {
    text-align: right;
}