@import './styles/stylesVariables.scss';
@import './styles/layoutVariables.scss';
@import './styles/mixins.scss';
@import '../public/assets/sass/overrides/layout_variables';

body {
  background: #fff;
  //background: #eff1f4;
  overscroll-behavior: none;

  scrollbar-width: thin;
  scrollbar-color: $scrollbarThumbColor $scrollbarTrackColor;
}

.login-body {
  background-image: none;
  background-color: var(--surface-200);
}

// Fix UI bug for frozen columns. Bug is relevant in the primereact v7. After update to v9 this code can be deleted //
.p-frozen-column {
  z-index: 1;
}

.p-toast {
  z-index: 99999;
}

.p-datatable-thead {
  z-index: 2 !important;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight,
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd,
.p-datatable .p-datatable-tbody > tr.p-highlight,
.p-datatable.p-datatable-selectable .p-datatable-tbody > tr.p-selectable-row:focus,
.p-datatable.p-datatable-selectable .p-datatable-tbody > tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  background: var(--surface-100);
  color: inherit;
}

// =========================================================================================== //

// VER-65 - [Notes] create link control is not aligned properly
.ql-tooltip {
  left: 0 !important;
}

.p-tooltip {
  max-width: 30rem !important;
}

.p-datepicker-trigger,
.p-autocomplete-dropdown {
  color: black !important;
}

// For all disabled elements
.p-disabled {
  font-weight: 600 !important;
  color: #000000 !important;
}

.p-datatable-wrapper {
  scrollbar-width: thin;
}

.scrollbar-thin {
  scrollbar-width: thin !important;
}

.p-datatable-emptymessage {
  font-size: 16px;

  td {
    text-align: center !important;
    padding: 20px !important;
  }
}

.legoViewDataTableWrapDesktop {
  .p-paginator {
    padding: 5px 20px !important;
  }
}

.input_description_styles {
  font-size: 9px;
  color: var(--surface-500);
}

// Common content container
.container {
  max-width: 1200px !important;
}

.containerXL {
  max-width: 1600px !important;
}

.contentOutsidePaddings {
  padding: $contentOutsidePaddings-Y $contentOutsidePaddings-X;
}

//======SCROLLBAR========//

// Styling for ScrollPanel component
.p-scrollpanel-bar {
  background-color: $scrollbarThumbColor !important;
  opacity: 1 !important;
  transition: background-color 0.2s !important;
}

.p-scrollpanel-bar:hover {
  background-color: $scrollbarThumbHoverColor !important;
}

/* WebKit */
::-webkit-scrollbar {
  @include scrollbar;
}

/* Track */
::-webkit-scrollbar-track {
  @include scrollbarTrack;
}

/* Thumb */
::-webkit-scrollbar-thumb {
  @include scrollbarThumb;
}

/* Thumb on hover */
::-webkit-scrollbar-thumb:hover {
  @include scrollbarThumbHover;
}

//======================//

//======!!! CHANGE STYLES FOR PRIMEREACT COMPONENTS !!!========//

// Custom Chip
.custom-chip {
  background: var(--primary-color);
  color: var(--primary-color-text);
}

// Data table chips
.chipsHorizontalScroll .p-chip-text {
  width: max-content;
}

.dataTableInCard .p-datatable-header {
  display: none;
}

.dataTableInCard .p-paginator-bottom {
  display: flex;
}

.dataTableInCard .p-datatable-thead {
  display: none;
}

.dataTableInCard .p-paginator-current {
  width: 100%;
}

.dataTableInCard .p-dropdown {
  display: none;
}

.topbar-menu-active {
  width: auto !important;
}

.topbar-menu-active::before {
  display: none !important;
}

// Buttons
.tabViewContentNoPaddingX .p-tabview-panels {
  padding-left: 0px;
  padding-right: 0px;
}

// Scroll top button
.customScrolltop {
  width: 2.5rem !important;
  height: 2.5rem !important;
  background-color: var(--primary-color) !important;
}

// Topbar
.p-toolbar {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  padding: 1.25rem;
  border-radius: 6px;
}

// Data table header
.p-datatable .p-datatable-header {
  background: #f8f9fa;
  color: #343a40;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  padding: 1rem;
  font-weight: 700;
}

.p-datatable-scrollable-header-box {
  margin: 0 !important;
}

.p-datatable .p-column-header-content {
  max-width: fit-content;
}

// Data table column filter
.p-column-filter {
  // padding-left: 10px;
}

// Card
.card {
  border-radius: 10px !important;
  box-shadow: 0px 10px 40px rgb(41 50 65 / 8%);
}

.p-card {
  border-radius: 10px !important;
  box-shadow: 0px 10px 40px rgb(41 50 65 / 8%);
}

.p-card .p-card-body {
  padding: 1.5rem;
}

// Fix header for Fieldset component (https://www.primefaces.org/primereact-v6/#/fieldset)
legend {
  width: max-content !important;
  margin-bottom: 0 !important;
  font-size: 1.2rem !important;
  margin-left: 20px !important;
}

// Centralize header of the fieldset in the center
.fieldsetWithCentralizedHeader {
  position: relative !important;

  legend {
    position: absolute !important;
    top: -18px !important;
    left: 5% !important;
    color: $gray !important;
    font-size: 1rem !important;
    font-weight: normal !important;
    // transform: translateX(-50%) !important;
    margin-left: 0 !important;
    background: #fff !important;
  }
}

//=====================================//

//==============AppTopbar===================//
.layout-wrapper .layout-main .layout-topbar {
  height: $mainHeader;
  padding: 7px 42px 16px 24px;
}

.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li > ul {
  top: calc(#{$mainHeader + 8px});
  border-top: unset !important;
}

.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li > ul:before {
  border-bottom: 15px solid #ffffff;
}

.layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu > li.profile-item > ul {
  left: 70px !important;
}

.profile-item {
  display: flex;
}

.profile_image_wrapper {
  display: inline-block;
  vertical-align: middle;
  border: 2px solid transparent;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  transition: border-color 0.2s;
}

@media screen and (max-width: 1024px) {
  .layout-wrapper .layout-main .layout-topbar {
    padding: 7px 24px !important;
  }

  .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu.topbar-menu-active {
    top: calc(#{$mainHeader + 16px});
    border-top: unset !important;
  }

  .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu:before {
    border-bottom: 15px solid #ffffff;
  }

  .profile-item {
    display: block;

    .p-link {
      padding-left: 6px !important;
    }
  }
}

//=====================================//

//COMMON ELEMENTS
.salesTaxEditor {
  span {
    padding-top: 6px !important;
  }
}

//Removing black dots from li and ul
ul {
  list-style-type: none;
}

.p-scrolltop {
  box-shadow: unset !important;
}

body .p-datatable {
  position: unset !important;
}

//Message primereact component wrapper
.p-message-wrapper {
  padding: 0.5rem 0.7rem !important;
}

//Messages primereact component wrapper
.p-inline-message {
  padding: 0.5rem !important;
}

//rounded mobile/tablet button size
.mobileRoundedButtonSize {
  width: 2.4em !important;
  height: 2.4em !important;
}

//no background styling for icon button
.iconBtn {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

//===create/edit invoices codes autocomplete===//
.invoicesAutocompleteWithCatalogsButton {
  position: relative;
}

.invoicesAutocompleteWithCatalogsButton .p-autocomplete {
  ul > li > input {
    padding-right: 50px !important;
  }

  i {
    right: 3rem !important;
  }
}

// Custom scrollbar
.p-scrollpanel.custombar .p-scrollpanel-bar {
  opacity: 1;
}

//==========================================//

//panel with action buttons fixed at the bottom (mobile view)
.fixedActionButtons {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: $fixedActionButtonsHeight;
  background-color: #fff;
  z-index: 99;

  button {
    border-radius: 0 !important;
  }
}

.stickyActionButtons {
  display: flex;
  position: sticky;
  bottom: 0;
  background: #fff;
  padding: 15px 0px;
  z-index: 1;
  @include gapForActionButtons;
}

//margin bottom for content if use .fixedActionButtons
.contentMarginBottom {
  margin-bottom: 70px !important;
}

.p-confirm-popup-message {
  margin-left: 0 !important;
}

//==========ACTIVE CLASS FOR MAIN NAV MENU============//
.navMenuActive {
  color: #fff !important;
  background: var(--primary-color) !important;

  i {
    color: #fff !important;
  }
}

//=====================================//

//==============TIMELINE CUSTOM MERKER===================//
%customMarker {
  display: flex;
  width: 1.3rem;
  height: 1.3rem;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 50%;
  z-index: 1;
}

.custom-marker-disable {
  @extend %customMarker;
  background-color: $customMarkerDisabled;
}

.custom-marker-success {
  @extend %customMarker;
  background-color: $success;
}

.custom-marker-upcoming {
  @extend %customMarker;
  background-color: transparent;
  border: 2px solid $success;
  position: relative; // Needed to position the inner dot correctly

  &::after {
    content: '';
    width: 0.4rem; // Size of the central dot
    height: 0.4rem;
    background-color: $success; // Color of the central dot
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); // Center the dot within the donut
  }
}

//Accepted/Paid
.custom-marker-success_2,
.custom-marker-success_27 {
  @extend %customMarker;
  color: #fff;
  background-color: $success;
}

//Rebilled
.custom-marker-success_21 {
  @extend %customMarker;
  color: #fff;
  background-color: $success_secondary;
}

//Declined/Not Paid/Error
.custom-marker-success_3,
.custom-marker-success_6,
.custom-marker-success_17,
.custom-marker-success_10 {
  @extend %customMarker;
  color: #fff;
  background-color: $danger;
}

//Outstanding
.custom-marker-success_0 {
  @extend %customMarker;
  color: #fff;
  background-color: $warning;
}

//In Process
.custom-marker-success_1,
.custom-marker-success_104,
.custom-marker-success_201 {
  @extend %customMarker;
  color: #fff;
  background-color: $info;
}

//In Process Debit
.custom-marker-success_203 {
  @extend %customMarker;
  color: #fff;
  background-color: $infoDark;
}

//Drafts
.custom-marker-success_4 {
  @extend %customMarker;
  color: #fff;
  background-color: $help;
}

//Archived
.custom-marker-success_-1,
.custom-marker-success_-3,
.custom-marker-success_-4,
.custom-marker-success_9,
.custom-marker-success_20 {
  @extend %customMarker;
  color: #fff;
  background-color: $gray;
}

//=====================================//

//==============CUSTOM EXPANDER FOR MOBILE DATA TABLE ROW===================//
.rowExpandedMobile {
  overflow: hidden;
  height: auto;
  max-height: 600px;
  transition: max-height 1s ease-out;
}

.rowCollapsedMobile {
  max-height: 0px;
  transition: max-height 0.5s ease-out;
}

//=====================================//

//==============SUBMENU===================//
body .p-menubar .p-submenu-list {
  z-index: 1000 !important;
}

%tabMenuFixedTop {
  width: 100% !important;
  left: 0 !important;
  z-index: 99 !important;
}

body .tabMenuFixedTopDesktop .p-tabview-nav-container {
  position: sticky !important;
  top: $mainHeader !important;
  @extend %tabMenuFixedTop;
}

body .tabMenuFixedTopMobile .p-tabview-nav-container {
  position: fixed !important;
  top: $mainHeaderMobile !important;
  @extend %tabMenuFixedTop;
}

body .tabMenuFixedTopiPadProOverlay .p-tabview-nav-container {
  position: fixed;
  top: $mainHeader;
  z-index: 999;
  @extend %overlayRoot;
}

body .tabMenuFixedTopiPadProStatic .p-tabview-nav-container {
  position: fixed;
  top: $mainHeader;
  z-index: 999;
  @extend %staticRoot;
}

body .tabMenuFixedTopDesktop .p-tabview .p-tabview-panels {
  padding: 0 !important;
}

body .subMenuRoot .p-tabview .p-tabview-panels {
  padding: 0 !important;
}

//=====================================//

//=============CURSOR POINTER=============//
.pointer {
  cursor: pointer !important;
}

//=====================================//

//=============BUTTONS=============//
//width of the button by content
.p-button-content {
  width: auto !important;
}

//=====================================//

//=============COMMON CHIP=============//
body .p-input-filled .p-chips-multiple-container {
  border-bottom-left-radius: unset !important;
  border-bottom-right-radius: unset !important;
  border: unset !important;
  background: unset !important;
  background-image: unset !important;
  background-size: unset !important;
  background-position: unset !important;
  background-origin: unset !important;
}

//=====================================//

//=============Menubar hightlighted item=============//
.menubarHighlightedItem {
  font-weight: 500 !important;
  color: $success !important;
}

//=====================================//

//=====================================//

//TEXT
//text styling
.text-underline {
  text-decoration: underline !important;
  cursor: pointer !important;
}

.underline {
  text-decoration: underline !important;
}

//font size
.small-text {
  font-size: 0.8rem !important;
}

.medium-size-text {
  font-size: 1.1rem;
}

.large-text {
  font-size: 1.2rem;
}

.extra-large-text {
  font-size: 1.4rem;
}

.xl-2-text {
  font-size: 1.6rem;
}

.xl-3-text {
  font-size: 1.8rem;
}

.xl-4-text {
  font-size: 2rem;
}

.page-title-text {
  font-size: 2rem;
}

//STATUS TEXT COLOR
//Outstanding
.status_text_0 {
  color: $warning;
}

//In Process
.status_text_1,
.status_text_104,
.status_text_201 {
  color: $info;
}

//In Process Debit
.status_text_203 {
  color: $infoDark;
}

//Accepted/Paid
.status_text_2,
.status_text_27 {
  color: $success;
}

//Rebilled
.status_text_21 {
  color: $success_secondary;
}

//Declined/Not Paid/Error
.status_text_3,
.status_text_6,
.status_text_17,
.status_text_10 {
  color: $danger;
}

//Drafts
.status_text_4 {
  color: $help;
}

//Archived
.status_text_-1,
.status_text_-3,
.status_text_-4,
.status_text_9,
.status_text_20 {
  color: $gray;
}

//=====================================//

//text colors
.text-color {
  //Font text color.
  color: var(--text-color) !important;
}

.text-secondary-color {
  //Muted font text color with a secondary level.
  color: var(--text-secondary-color) !important;
}

.text-disabled-color {
  //Gray color.
  color: $gray !important;
}

.primary-color {
  //Primary color of the theme. ////////////////////////////////////////////////
  color: var(--primary-color) !important;
}

.primary-color-text {
  //Text color when background is primary color.////////////////////////////////////
  color: var(--primary-color-text) !important;
}

//=====================================//

.fc-view-container {
  overflow-x: scroll;
}

.fc-view.fc-agendaDay-view.fc-agenda-view {
  width: 100%;
  overflow-x: scroll;
}

/* **For 2 day view** */
.fc-view.fc-agendaWeek-view.fc-agenda-view {
  width: 300%;
  overflow-x: scroll;
}

.fc-view.fc-month-view.fc-agenda-view {
  min-width: 100%;
  max-width: 500%;
  overflow-x: scroll;
}

.fc-resource-cell {
  max-width: 100px !important;
}

//autocomplete input
.p-autocomplete-dd .p-autocomplete-dropdown {
  position: absolute;
  top: 5px;
  right: 0;
  background: transparent !important;
}

.p-fluid .p-autocomplete-dd .p-autocomplete-input {
  position: relative;
}

.p-autocomplete-dd .p-autocomplete-input {
  padding-right: 40px !important;
}

.p-autocomplete-dd .p-autocomplete-dropdown .pi-chevron-down:before {
  color: $gray;
}

// .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
//   border-top: unset;
//   border-right: unset;
//   border-left: unset;
//   border-radius: unset;
//   box-shadow: none !important;
// }
// .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
//   border-top: unset;
//   border-right: unset;
//   border-left: unset;
//   border-radius: unset;
//   box-shadow: none !important;
// }
// //=====================================//

//=====Highlight last visits dates======//
.lastVisitsDateTempate {
  background-color: lightgray;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  padding: 0;
}

//=====================================//

.pi-sort-alt {
  display: none;
}

.p-splitbutton {
  max-height: 3rem;
}

.hidden {
  display: none;
}

.p-fieldset-legend-text {
  white-space: normal;
}

.p-datatable-responsive .p-datatable-tbody > tr.p-datatable-row > td .p-column-title {
  width: 50%;
}

.p-datascroller .p-datascroller-list .row-details {
  padding: 0.5em;
  min-height: 4em;
}

body .p-datatable .p-datatable-thead > tr > th.p-highlight {
  background-color: transparent;
  border-top-color: transparent;
  color: #000000;
}

body .p-datatable .p-datatable-thead > tr > th.p-highlight .p-inputtext {
  color: #000000;
}

body .p-datatable .p-datatable-scrollable-wrapper thead tr th {
  outline: none !important;
}

.pi.pi-sort-up:before {
  color: #000000;
}

.pi.pi-sort-down:before {
  color: #000000;
}

%commonPlaceholder {
  color: $gray !important;
  font-size: 13px;
}

body ::placeholder {
  @extend %commonPlaceholder;
}

.p-dropdown-label.p-inputtext.p-placeholder {
  @extend %commonPlaceholder;
}

body #claimsRoot .p-tabmenu .p-tabmenu-nav {
  background-color: transparent;
}

body #claimsRoot .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  border-color: transparent;
}

body #claimsRoot .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight {
  border-color: $success;
  background-color: transparent;
}

//header gear menu
.layout-wrapper .layout-main .layout-topbar #topbarMenuRoot .layout-topbar-menu-wrapper .topbar-menu > li > ul button {
  outline: none;
}

//PatientDetails
//DataScroller for invoices table (mobile view)
body .patientDetailsRoot .p-datascroller-list {
  padding-bottom: 50px !important;
}

//=====================================//

//=============Expanded view calendar=============//
body .expandedViewCalendar .p-datepicker .p-datepicker-header {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

body .expandedViewCalendar .p-datepicker table {
  font-size: 12px !important;
}

body .expandedViewCalendar .p-datepicker table td {
  padding: 3px;
}

body .expandedViewCalendar .p-datepicker table td > span {
  width: 2.2em !important;
  height: 2.2em !important;
}

body .expandedViewCalendar .p-datepicker table th > span {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: unset !important;
  height: unset !important;
}

body .expandedViewCalendar .p-datepicker table td.p-datepicker-today > span {
  box-shadow: unset !important;
  border-color: transparent !important;
}

//Accepted
//Paid
.calendar_cm_status_2 .p-highlight,
.calendar_cm_status_27 .p-highlight,
.calendar_cm_status_2 .p-highlight .lastVisitsDateTempate,
.calendar_cm_status_27 .p-highlight .lastVisitsDateTempate {
  color: #fff !important;
  background-color: $success !important;
}

//Rebilled
.calendar_cm_status_21 .p-highlight,
.calendar_cm_status_21 .p-highlight .lastVisitsDateTempate {
  color: #fff !important;
  background-color: $success_secondary !important;
}

//Declined/Not Paid/Error
.calendar_cm_status_3 .p-highlight,
.calendar_cm_status_6 .p-highlight,
.calendar_cm_status_17 .p-highlight,
.calendar_cm_status_10 .p-highlight,
.calendar_cm_status_3 .p-highlight .lastVisitsDateTempate,
.calendar_cm_status_6 .p-highlight .lastVisitsDateTempate,
.calendar_cm_status_17 .p-highlight .lastVisitsDateTempate,
.calendar_cm_status_10 .p-highlight .lastVisitsDateTempate {
  color: #fff !important;
  background-color: $danger !important;
}

//Outstanding
.calendar_cm_status_0 .p-highlight,
.calendar_cm_status_0 .p-highlight .lastVisitsDateTempate {
  color: #fff !important;
  background-color: $warning !important;
}

//In Process
.calendar_cm_status_1 .p-highlight,
.calendar_cm_status_104 .p-highlight,
.calendar_cm_status_201 .p-highlight,
.calendar_cm_status_1 .p-highlight .lastVisitsDateTempate,
.calendar_cm_status_104 .p-highlight .lastVisitsDateTempate,
.calendar_cm_status_201 .p-highlight .lastVisitsDateTempate {
  color: #fff !important;
  background-color: $info !important;
}

//In Process Debit
.calendar_cm_status_203 .p-highlight,
.calendar_cm_status_203 .p-highlight .lastVisitsDateTempate {
  color: #fff !important;
  background-color: $infoDark !important;
}

//Drafts
.calendar_cm_status_4 .p-highlight,
.calendar_cm_status_4 .p-highlight .lastVisitsDateTempate {
  color: #fff !important;
  background-color: $help !important;
}

//Archived
.calendar_cm_status_-1 .p-highlight,
.calendar_cm_status_-3 .p-highlight,
.calendar_cm_status_-4 .p-highlight,
.calendar_cm_status_9 .p-highlight,
.calendar_cm_status_20 .p-highlight,
.calendar_cm_status_-1 .p-highlight .lastVisitsDateTempate,
.calendar_cm_status_-3 .p-highlight .lastVisitsDateTempate,
.calendar_cm_status_-4 .p-highlight .lastVisitsDateTempate,
.calendar_cm_status_9 .p-highlight .lastVisitsDateTempate,
.calendar_cm_status_20 .p-highlight .lastVisitsDateTempate {
  color: #fff !important;
  background-color: $gray !important;
}

//=====================================//

//==========Create/Edit invoices============//
.createInvoiceRoot .p-scrollpanel-content {
  padding-bottom: 0 !important;
}

.createInvoiceCatalogsRootMobile .p-tabview-panels {
  padding: 0 !important;
}

//splitter
.createInvoiceRoot .p-splitter {
  border: none !important;
  background: transparent !important;
  border-radius: unset !important;
  color: unset !important;
}

.createInvoiceRoot .p-splitter-gutter {
  margin: 0 10px !important;
}

.createInvoiceRoot .p-message-wrapper {
  button {
    display: none !important;
  }
}

//==========================//
.p-slidemenu .p-menuitem-active > .p-submenu-list {
  padding-bottom: 50px !important;
}

.breadcrumbMobile .p-breadcrumb ul li .p-menuitem-text {
  width: max-content !important;
}

//sidebar
.quickPickCatalogSidebarMobile .p-sidebar-header {
  padding: 5px 5px 0px 5px !important;
}

//=====================================//

//LegoView
//desctop -> split btn dropdown
body .desctopDataTableActionMenu .p-menu.p-menu-overlay {
  left: -80px !important;
  // max-width: max-content !important;
  z-index: 99999 !important;
}

//tablet -> three dots button menu
body .tabletDataTableActionMenu .p-menu.p-menu-overlay {
  // max-width: max-content !important;
  z-index: 99999 !important;
}

//table columns padding
body .legoViewWrap .p-filter-column {
  padding-top: 0 !important;
}

body .legoViewWrap .p-sortable-column {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.hideDataTableHeader .p-datatable-thead {
  display: none;
}

//paginator container
.legoViewWrap .p-datatable .p-paginator-top {
  position: fixed;
  top: calc(#{$mainHeader} + #{$subMenu} + #{$mainSearchPanel} + #{$chipsPanel});
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  z-index: 100;
}

%legoViewDataTableWrap {
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.legoViewDataTableWrapDesktop .p-datatable .p-paginator-bottom {
  position: sticky;
  @extend %legoViewDataTableWrap;
}

.legoViewDataTableWrapMobile .p-datatable .p-paginator-bottom {
  position: fixed;
  @extend %legoViewDataTableWrap;
}

.iPadProInLandscapeOverlayRoot .p-datatable .p-paginator-bottom {
  z-index: 100;
  bottom: 0;
  right: 0;
  @extend %overlayRoot;
  position: fixed;
}

.iPadProInLandscapeStaticRoot .p-datatable .p-paginator-bottom {
  z-index: 100;
  bottom: 0;
  right: 0;
  @extend %staticRoot;
  position: fixed;
}

.legoViewWrap thead .p-inputtext {
  background-color: #fff !important;
}

.legoViewWrap thead .p-inputtext:focus {
  background-color: rgb(230, 230, 230) !important;
}

.legoViewWrap thead .p-dropdown {
  background-color: #fff !important;
}

.legoViewWrap .p-paginator .p-dropdown {
  border-bottom: none !important;
  background: #fff !important;
}

//chips container
.legoViewWrap .p-chips-multiple-container {
  background: #fff !important;
}

//SubMenu
//History Pop-up
body .historyDialog .p-dialog-title {
  font-size: 1em !important;
}

//=====================================//

// Autocomplete dropdown with sugestions
.p-autocomplete-panel {
  z-index: 10000000 !important;
}

//=====================================//

//Tooltip
.p-tooltip .p-tooltip-arrow {
  display: block !important;
}

.p-tooltip {
  z-index: 99999999 !important;
}

//=====================================//

//Header
body .layout-topbar {
  box-shadow: none !important;
}

//=====================================//

//Left Sidebar menu
body .layout-sidebar {
  box-shadow: none !important;
  border-right: 1px solid $borderGray !important;
  z-index: 1 !important;
}

//=====================================//

//Filters sidebar
body .sidebarFilters .p-sidebar-content {
  padding-bottom: $filtersSidebarFooterHeight !important;
}

body .sidebarFilters .p-sidebar-header {
  justify-content: space-between !important;
}

//=====================================//

//App.js
//layout-content
.layout-wrapper .layout-main .layout-breadcrumb {
  box-shadow: none !important;
  border-bottom: 1px solid $borderGray !important;
}

//=====================================//

//Autocomplite
//chip
body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-token {
  background: unset !important;
  color: unset !important;
  padding-left: 0 !important;
}

//overlay panel element
body .p-autocomplete-item {
  white-space: inherit;
}

// body .p-connected-overlay-enter-done {
//   z-index: 999999 !important;
// }
//=====================================//

//AppMenu
.layout-wrapper .layout-sidebar .layout-menu > li > a {
  display: none !important;
}

//=====================================//

//Colored Status text
%badge {
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.customer-badge-mobile {
  @extend %badge;
  padding: 0em 0.9rem;
}

//Accepted/Paid
.cm_status_2,
.cm_status_27 {
  color: #fff;
  background-color: $success;
}

//Rebilled
.cm_status_21 {
  color: #fff;
  background-color: $success_secondary;
}

//Declined/Not Paid/Error
.cm_status_3,
.cm_status_6,
.cm_status_17,
.cm_statusr_10 {
  color: #fff;
  background-color: $danger;
}

//Outstanding
.cm_status_0 {
  color: #fff;
  background-color: $warning;
}

//In Process
.cm_status_1,
.cm_status_104,
.cm_status_201 {
  color: #fff;
  background-color: $info;
}

//In Process (Debit)
.cm_status_203 {
  color: #fff;
  background-color: $infoDark;
}

//Drafts
.cm_status_4 {
  color: #fff;
  background-color: $help;
}

//Archived
.cm_status_-1,
.cm_status_-3,
.cm_status_-4,
.cm_status_9,
.cm_status_20 {
  color: #fff;
  background-color: $gray;
}

//=====================================//

//Scroll top button
.customScrolltop {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: var(--primary-color) !important;
}

.customScrolltop .p-scrolltop-icon {
  font-size: 1rem;
  color: var(--primary-color-text);
}

//=====================================//

//STATUS-BAR (BAR-CHART)
.status-bar {
  height: 18px;
  width: 100%;
  background-color: #e8e8e8;
  border-radius: 6px;

  .status-bar-value {
    height: 100%;
    color: #ffffff;
    text-align: right;
    padding-right: 4px;
    padding-top: 1px;
    box-sizing: border-box;
    border-radius: 6px;
  }

  //Outstanding
  &.status-bar-0 {
    .status-bar-value {
      background-color: $warning;
    }

    ~ i {
      color: $warning;
    }
  }

  //In Process
  &.status-bar-1,
  &.status-bar-104,
  &.status-bar-201 {
    .status-bar-value {
      background-color: $info;
    }

    ~ i {
      color: $info;
    }
  }

  //In Process Debit
  &.status-bar-203 {
    .status-bar-value {
      background-color: $infoDark;
    }

    ~ i {
      color: $infoDark;
    }
  }

  //Declined/Not Paid/Error
  &.status-bar-3,
  &.status-bar-6,
  &.status-bar-17,
  &.status-bar-10 {
    .status-bar-value {
      background-color: $danger;
    }

    ~ i {
      color: $danger;
    }
  }

  //Accepted/Paid
  &.status-bar-2,
  &.cm_status_27 {
    .status-bar-value {
      background-color: $success;
    }

    ~ i {
      color: $success;
    }
  }

  //Rebilled
  &.status-bar-21 {
    .status-bar-value {
      background-color: $success_secondary;
    }

    ~ i {
      color: $success_secondary;
    }
  }

  //Draft
  &.status-bar-4 {
    .status-bar-value {
      background-color: $help;
    }

    ~ i {
      color: $help;
    }
  }

  //Archived
  &.status-bar-4 {
    .status-bar-value {
      background-color: $gray;
    }

    ~ i {
      color: $gray;
    }
  }
}

//=====================================//

// Create new patient accordion
.createNewPatientFormAccordion .p-accordion-tab {
  margin-bottom: 10px !important;
  box-shadow: none !important;
}

.createNewPatientFormAccordion .p-accordion-content {
  padding: 1rem 0rem !important;
}

//=====================================//

// Create new patient tab view
.createNewPatientFormTabView .p-tabview-panels {
  padding: 1rem 0rem !important;
}

//=====================================//

// Patient profile -> MSP eligibility card Inplace element
.mspEligibilityCardDOS .p-inplace-display {
  padding: 0.3rem 0rem !important;
}

/* @media */
.printContent {
  display: none !important;
}

@media print {
  .no_printContent {
    display: none !important;
  }

  .printContent {
    display: block !important;
  }
}

// data table paginator for mobile in portrait mode
@media screen and (max-width: 500px) {
  body .legoViewWrap .p-datatable.p-datatable-customers .p-paginator {
    padding: 0 !important;
  }

  body .legoViewWrap .p-paginator .p-paginator-current {
    width: 100% !important;
  }
}

// Recponsive data table for PrivateInvoiceCard
.datatable-responsive-layout .p-datatable-responsive-layout .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.printed-datatable-responsive-layout .p-datatable-responsive-layout .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 1023px) {
  .datatable-responsive-layout .p-datatable.p-datatable-responsive-layout .p-datatable-thead > tr > th,
  .datatable-responsive-layout .p-datatable.p-datatable-responsive-layout .p-datatable-tfoot > tr > td {
    display: none !important;
  }

  .datatable-responsive-layout .p-datatable.p-datatable-responsive-layout .p-datatable-tbody > tr > td {
    text-align: left;
    display: block;
    width: 100%;
    float: left;
    clear: left;
    border: 0 none;
    padding: 10px 0;
  }

  .datatable-responsive-layout .p-datatable.p-datatable-responsive-layout .p-datatable-tbody > tr > td .p-column-title {
    padding: 0.4rem;
    min-width: 100px;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4rem;
    font-weight: bold;
  }

  .datatable-responsive-layout .p-datatable.p-datatable-responsive-layout .p-datatable-tbody > tr > td:last-child {
    border-bottom: 1px solid var(--surface-d);
  }
}

//=====================================//

//Spinner color
// @keyframes p-progress-spinner-color {
//   0%, 100% {
//       stroke: #607d8b
//   }
// }
//=====================================//
