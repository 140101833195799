@import '../../../../../styles/stylesVariables.scss';

.privateInvoicePreviewWrap {
    max-width: 1100px;
}

.privateInvoicePreviewButtonsWrap {
    position: sticky;
    top: $mainHeader;
    background-color: #fff;
}

.privateInvoicePreviewInvoiceTitleWrapDesktop {
    display: block !important;
}

.privateInvoicePreviewInvoiceTitleWrapMobile {
    display: none !important;
}

.privateInvoicePreviewInvoiceHeaderWrap {
    display: flex;
}

.privateInvoicePreviewInvoiceToWrap {
    display: flex;
}

.privateInvoicePreviewInvoiceSummaryWrap {
    display: flex;
}

.addressLayoutWrap {
    display: flex;
}

.privateInvoicePreviewDataTable {
    .serviceNumberBodyTemplate {
        width: 4% !important;
    }

    .serviceBodyTemplate {
        width: 25% !important;
    }

    .providerBodyTemplate {
        width: 23% !important;
    }

    .quantityBodyTemplate {
        width: 8% !important;
    }

    .amountBodyTemplate {
        width: 10% !important;
    }

    .feeAmountBodyTemplate {
        width: 10% !important;
    }

    .discountBodyTemplate {
        width: 8% !important;
    }

    .salesTaxBodyTemplate {
        width: 12% !important;
    }
}


@media screen and (max-width: 1023px) {
    .addressLayoutWrap {
        display: block;
    }

    .privateInvoicePreviewDataTable {
        .serviceNumberBodyTemplate {
            width: unset !important;
        }

        .serviceBodyTemplate {
            width: unset !important;
        }

        .providerBodyTemplate {
            width: unset !important;
        }

        .quantityBodyTemplate {
            width: unset !important;
        }

        .amountBodyTemplate {
            width: unset !important;
        }

        .feeAmountBodyTemplate {
            width: unset !important;
        }

        .discountBodyTemplate {
            width: unset !important;
        }

        .salesTaxBodyTemplate {
            width: unset !important;
        }
    }
}

@media screen and (max-width: 649px) {
    .privateInvoicePreviewInvoiceTitleWrapDesktop {
        display: none !important;
    }

    .privateInvoicePreviewInvoiceTitleWrapMobile {
        display: flex !important;
    }

    .privateInvoicePreviewInvoiceHeaderWrap {
        display: flex;
        flex-direction: column;
    }

    .privateInvoicePreviewInvoiceToWrap {
        display: flex;
        flex-direction: column;
    }

    .privateInvoicePreviewInvoiceSummaryWrap {
        display: flex;
        flex-direction: column;

        .noteSectionWrap {
            padding-bottom: 25px;
        }
    }

    .addressLayoutWrap {
        display: block;
    }
}