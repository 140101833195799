$catalogsHeaderInfoHeight: 50px;
$catalogTabsHeight: 36px;

.e_claim_catalog_tab_view {
  height: calc(100% - #{$catalogsHeaderInfoHeight} - #{$catalogTabsHeight});

  .p-tabview-panels {
    height: 100%;
  }
}

// Mobile only in portrait mode and low screen resolution
@media (min-width: 350px) and (max-width: 388px) {}

// Mobile only in portrait mode
@media (min-width: 389px) and (max-width: 639px) {}

// Mobile in landscape mode
@media (min-width: 350px) and (max-width: 959px) {}

// Tablets only
@media (min-width: 640px) and (max-width: 1023px) {}

// Tablets and mibile
@media screen and (max-width: 1023px) {}