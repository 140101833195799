$newPatientFormMobileWidth: 510px;
$newPatientFormDesktopWidth: 1040px;

.newPatientFormMobile {
  max-width: $newPatientFormMobileWidth;
}

.newPatientFormDesktop {
  max-width: $newPatientFormDesktopWidth;
}

.wrapForLeftAndRightParts {
  width: 485px;
}

.headerContentLeft {
  width: 42px;
}

.accordionRoot {
  a {
    background: #fbfbfb !important;
    text-decoration: none !important;
  }

  a:hover {
    background: #efefef !important;
    text-decoration: none !important;
  }

  a:focus {
    background: #efefef !important;
    text-decoration: none !important;
  }
}
