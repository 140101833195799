.feeTotalWrap {
    min-width: 100px !important;
}

.barChartWrap:hover {
    background: rgba(0, 0, 0, 0.04);
}

.totalCountWrap {
    min-width: 140px !important;
}

.statusCodeTextHeaderRoot {
    min-width: 75px;
}

.rowActionButton {
    width: 2.4rem !important;
    height: 2.4rem !important;
}

@media screen and (max-width: 576px) {
    .totalCountWrap {
        min-width: 70px !important;
    }

    .feeTotalWrap {
        min-width: 80px !important;
    }
}