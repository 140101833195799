@import '../../../../public//assets/sass/overrides/layout_variables';

.successMessage {
  font-size: 1.2em;
  color: $success;
}

.errorMessage {
  font-size: 1.2em;
  color: $danger;
}

.successIcon {
  font-size: 6em;
  color: $success;
}

.errorIcon {
  font-size: 6em;
  color: $danger;
}