@import '../../styles/stylesVariables.scss';

.dataTable {
  .p-datatable-emptymessage {
    font-size: 16px;

    td {
      display: flex;
      justify-content: center;
      padding: 20px !important;
    }
  }
}

.dataTable .p-datatable-table {
  width: 100%;
}

.dataTable .p-column-filter-clear-button {
  z-index: -1;
}

.dataTable .p-datatable-header {
  position: sticky;
  top: $mainHeader;
  z-index: 99;
}

.dataTable .p-paginator-bottom {
  position: sticky;
  bottom: 0;
  z-index: 99;
}

@media only screen and (max-width: 768px) {
  .dataTable .p-paginator-current {
    display: none;
    // width: 100%;
  }

  .dataTable .p-dropdown {
    display: none;
  }

  .dataTable .p-datatable-thead {
    display: none;
  }
}