.iconWrap {
    width: 2.4em;
    height: 2.4em;
    z-index: 0;
}

.timeLine {
    margin-left: -90% !important;
    z-index: 1 !important;
}

.mainDetails {
    z-index: 2 !important;
}

.calendarWrap {
    max-width: 300px !important;
}

.customizedMarkerIcon {
    font-size: 10px;
}