@import "../../styles/stylesVariables.scss";
$right: 17px;

%footer {
    position: fixed;
    bottom: 0;
    background: #fff;
    height: $filtersSidebarFooterHeight;
}

.mobileSidebar {
    width: $mobileFiltersSidebarWidth  !important;
}

.desktopSidebar {
    width: $filtersSidebarWidth  !important;
}

.footerRoot {
    @extend %footer;
    width: calc(#{$filtersSidebarWidth} - #{$right});
    right: $right;
}

.footerRootMobile {
    @extend %footer;
    width: 100%;
    right: 0;
}

.footerButton {
    height: inherit;
    border-radius: unset;
    max-height: unset !important;
}

//Tablets and iPhone X screen type devices
@media screen and (min-device-width: 768px) and (max-device-height: 1366px) {
    .mobileSidebar {
        width: 50% !important;
    }

    .footerRootMobile {
        width: 50%;
    }
}