%x_btn {
    position: absolute !important;
    right: 0px;
    height: inherit;
    width: 40px;
    color: rgba(0, 0, 0, 0.87) !important;
    border-radius: unset;
    border-top-right-radius: unset !important;
}

.inputRoot {
    height: inherit;
    position: relative !important;
    width: 100%;
}

.input {
    height: inherit;
    width: 100% !important;
    border-radius: unset;
    border-top-right-radius: unset !important;
    border-top-left-radius: unset !important;
    background-image: unset !important;
}

.input:focus {
    background-image: 
    linear-gradient(to bottom, var(--primary-color), var(--primary-color)), 
    linear-gradient(to bottom, rgba(0, 0, 0, 0.38), 
    rgba(0, 0, 0, 0.38)) !important;
}

.searchIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
}

.x_button {
    @extend %x_btn;
    background: lightgray !important;
}

.x_button_mobile {
    @extend %x_btn;
    background: transparent !important;
}


.bar {
    margin:0 auto;
    width:575px;
    border-radius:30px;
    border:1px solid #dcdcdc;
    height: 35px;
    margin-top: 10px;
}

.bar:hover {
    box-shadow: 1px 1px 8px 1px #dcdcdc;
}
  
.bar:focus-within {
    box-shadow: 1px 1px 8px 1px #dcdcdc;
    outline:none;
}

.searchbar{
    height:25px;
    border:none;
    width:520px;
    font-size:16px;
    outline: none
}