.timeline-container {
  .p-timeline-event-opposite {
    display: none; // Completely hide opposite content
    width: 0 !important; // Make sure it doesn't take up any space
    padding: 0 !important; // Remove any padding
    margin: 0 !important; // Remove any margin
  }

  .p-timeline-event-content {
    flex: 1; // Ensure content takes full width
    // padding-right: 600px !important;

    // @media (max-width: 1183px) {
    //   padding-right: 0px !important; // Set padding to 0px on small screens
    // }
  }

  .p-timeline-event:last-child .p-timeline-event-separator {
    width: 0px !important; // Hides the connector line for the last event
  }

  .p-timeline {
    justify-content: flex-start !important; // Ensure alignment starts from the left
    padding-top: 1rem; // Add padding to the top of the timeline
    padding-left: 2rem;
  }

  .p-timeline-event-separator {
    position: absolute; // Make the separator absolutely positioned
    left: 0; // Align it to the far left
    top: 0;
    height: 100%; // Ensure it covers the height of the container
    width: 2px; // Adjust the width of the separator
    background-color: #ccc; // Set the color of the separator
  }

  .note-card-container {
    max-width: 100%; // Allow the card to take the full width of its container
    word-wrap: break-word; // Ensure long words are broken into the next line
    overflow-wrap: break-word; // Also break words on long lines
    white-space: normal; // Ensure text wraps normally (in case it's set otherwise)
    padding-left: 1rem;
    max-width: 800px;
  }
}
