@import '../../styles/mixins.scss';
@import '../../styles/stylesVariables.scss';
@import '../../../public/assets/sass/overrides/layout_variables';

$catalogsHeaderInfoHeight: 50px;
$catalogTabsHeight: 36px;
$catalogLabelHeight: 25px;
$scrollBarHeight: 0px;
$contentPadding: $contentOutsidePaddings-Y 0px;
$desktopFormContainerTabletWidth: 390px;
$mobileCatalogsHeaderHeight: 40px;

%dataScrollertableFooter {
  display: flex;
  width: 100%;
  padding: 0;
  align-items: center;
  background: #fff;
  transition: all 0.4s;
  cursor: pointer;
}

.p-tabview .p-tabview-panels {
  padding: 0;
}

.content_root {
  position: relative;
}

.form_root_container {
  // width: 1800px;
  width: 100%;
  padding: 0px 25px;
  column-gap: 15px;
}

.desktop_form_container {
  max-width: $desktopFormContainerMaxWidth;
  min-width: $desktopFormContainerMaxWidth;
  width: 100%;
  height: calc(100vh - #{$mainHeader});
  padding: $contentPadding;
}

.mobile_form_container {
  display: none;
}

.catalogs_card {
  height: 100%;

  .catalogs_card_header {
    row-gap: 2.5rem;
    column-gap: 2.5rem;
  }

  .p-card-body {
    height: 100%;
  }

  .p-card-content {
    height: 100%;
  }
}

// .scroll_panel {
//   width: 100%;
//   height: 100%;

//   .p-scrollpanel-content {
//     padding-right: 10px;
//     padding-bottom: 35px !important;
//   }

//   .p-scrollpanel-wrapper {
//     border-right: 12px solid transparent;
//   }
// }

.form_card {
  height: 100%;

  .p-card-body {
    height: 100%;
  }

  .p-card-content {
    height: calc(100% - 45px);
    padding-top: 0;
  }
}

.codePickerWrap {
  height: auto !important;
  min-height: 90px !important;
}

.codePickerDesktopWrap {
  display: block;
}

.codePickerMobileWrap {
  display: none;
}

.formWithCataogsStickyActionButtons {
  padding-right: 15px;
  @include gapForActionButtons;
}

.searchInput {
  height: 32px !important;
  width: inherit !important;
}

.two_inputs_in_a_row {
  display: flex;
  gap: 10px;
}

.quick_pick_items_list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
}

.catalogsSidebar .p-paginator {
  padding: 0.5rem 0.3rem;
}

.catalogsSidebar .p-datatable-scrollable-header {
  display: none !important;
}

//dataScroller
.catalogsSidebarMobile .p-datascroller .p-datascroller-content {
  padding: 0 !important;
}

.catalogsSidebar .p-sidebar-header {
  padding: 5px 5px 0px 5px !important;
}

.catalogsSidebar .p-sidebar-content {
  padding: 0 0.4rem !important;
  overflow-x: hidden !important;
}

.catalogsSidebarMobile .p-slidemenu {
  border: none !important;
  width: 100% !important;
  padding: 0 !important;
}

// .catalogsSlideMenuDesktop .p-slidemenu .p-slidemenu-backward {
//   right: 17px !important;
// }

// //move Back button to the top side of slide menu
// .catalogsSlideMenuDesktop .p-slidemenu .p-menuitem-active>.p-submenu-list {
//   padding-top: 40px !important;
// }

// .catalogsSlideMenuDesktop .p-slidemenu-backward {
//   position: absolute !important;
//   top: 0;
// }

// DESKTOP CATALOGS //
.desktop_catalog_container {
  width: calc(100% - #{$desktopFormContainerMaxWidth});
  height: calc(100vh - #{$mainHeader});
  padding: $contentPadding;
}

.catalogs_no_patient_selected {
  display: block;
  padding: 0.5rem 0.75rem;
}

.catalog_tab_view {
  // height: calc(100% - #{$catalogsHeaderInfoHeight} - #{$catalogTabsHeight} - #{$catalogLabelHeight});
  height: calc(100% - #{$catalogTabsHeight} - #{$catalogLabelHeight});

  .p-tabview-panels {
    height: calc(100% - #{$mobileCatalogsHeaderHeight});
  }
}

.catalog_tab_without_header_view {
  height: calc(100% - #{$catalogTabsHeight});

  .p-tabview-panels {
    height: 100%;
  }
}

.quickPickBlockWrap {
  display: flex;
}

.toggleCatalogsDesktopButton {
  display: block !important;
}

.toggleCatalogsMobileButton {
  display: none !important;
}

.desktopCatalogSlideMenuWrap {
  min-width: 17.5rem;

  .p-slidemenu .p-menuitem-active > .p-submenu-list {
    padding-top: 40px !important;
  }

  .p-slidemenu-backward {
    position: absolute !important;
    top: 0;
    height: 40px;
  }
}

.dataScrollerCodeColumn {
  display: flex;
  align-items: center;
  width: 65px !important;
  min-height: 45px !important;
}

.dataScrollerLableColumn {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100% !important;
  min-height: 45px !important;
  word-wrap: break-word;
  word-break: break-all;
  padding: 0.5rem;
}

.dataScrollerItemRoot {
  background: $hoverGray;
}

.dataScrollerItemRootHighlight {
  display: flex;
  padding: 0;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.dataScrollerItemRootHighlight:hover {
  background: $hoverGray;
}

.datatable_emptymessage {
  padding: 10px;
  font-size: 16px;

  li {
    border: none !important;
  }
}

.desktopCatalogsSearchWrap {
  position: relative;
  border-bottom: 1px solid $customDataTableBorderColor;
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding-top: 7px;
  column-gap: 15px;
}

.patient_chip_scroll {
  overflow-x: auto;
  width: 42vw;
}

%invoicesCatalogBreadcrumb {
  border-left: none !important;
  border-right: none !important;
  border-radius: unset !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.createInvoiceCatalogsRootMobile .p-breadcrumb {
  @extend %invoicesCatalogBreadcrumb;
  border-top: none !important;
}

.catalogsRoot .p-breadcrumb {
  @extend %invoicesCatalogBreadcrumb;
}

.catalogsRoot .p-card .p-card-content {
  padding-top: 0;
}

.catalogsRoot .p-datascroller,
.catalogsSidebarMobile .p-datascroller {
  width: 100% !important;
}

.catalogsRoot .p-tabview .p-tabview-panels {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.catalogsRoot .p-slidemenu {
  width: unset !important;
  border-radius: unset !important;
  border-bottom: none !important;
  border-left: none !important;
  border-top: none !important;
  padding: 0 !important;
}

.catalogsRoot .p-slidemenu .p-menuitem {
  padding-right: 10px !important;
}

.p-menuitem-highlight {
  background-color: $hoverGray;
}

.catalogsRoot .p-slidemenu .p-slidemenu-backward,
.catalogsSidebarMobile .p-slidemenu .p-slidemenu-backward {
  // right: 17px !important;
  position: fixed;
  bottom: 0;
  left: 0;
  padding-left: 30px !important;
  padding-top: 10px !important;
  background: #fff !important;
  max-height: 40px !important;
}

// ============== //

// MOBILE CATALOGS //
.mobileCatalogsContentRoot {
  display: flex;
  position: relative;
  width: 100%;

  .showMobileSlideMenu {
    width: 100%;
    transition: all 0.4s;
  }

  .hideMobileSlidemenu {
    width: 100%;
    padding-right: 30px;
    margin-left: -100%;
    transition: all 0.4s;
  }

  .showMobileDataScroller {
    width: 100%;
    transition: all 0.4s;
  }

  .hideMobileDataScroller {
    width: 100%;
    padding-left: 30px;
    margin-right: -100%;
    transition: all 0.4s;
  }

  .showDataScrollertableFooter {
    @extend %dataScrollertableFooter;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .hideDataScrollertableFooter {
    @extend %dataScrollertableFooter;
  }

  .dataScrollerFooterBackIcon {
    font-size: 20px;
  }

  // .p-slidemenu-wrapper {
  //   height: 100% !important;
  // }
}

// ============== //

// FireFox browser only //
@-moz-document url-prefix() {
  .p-scrollpanel-content {
    padding-bottom: 7px !important;
  }

  .p-scrollpanel-content {
    padding-right: 20px !important;
  }
}

// ============== //

// Mobile only in portrait mode and low screen resolution
@media (min-width: 350px) and (max-width: 388px) {
  // STEP 1 //
  .mobile_form_container {
    display: block !important;
    max-width: 360px !important;

    .teleplan_time_input_wrap {
      width: 143px;
    }
  }
}

// Mobile only with wide screen in portrait mode
@media (min-width: 389px) and (max-width: 639px) {
  // STEP 1 //
  .mobile_form_container {
    display: block;
    max-width: 390px !important;
  }
}

// Mobile only in portrait mode
@media (min-width: 350px) and (max-width: 639px) {
  // STEP 1 //
  .two_inputs_in_a_row {
    display: flex;
    flex-direction: column !important;
  }
}

// Mobile in landscape mode
@media (min-width: 350px) and (max-width: 959px) {
  // STEP 1 //
  .mobile_form_container {
    display: block !important;

    .p-card .p-card-content {
      padding-bottom: 0;
      padding-top: 0;
    }
  }
}

// Tablets only
@media (min-width: 640px) and (max-width: 1023px) {
  // STEP 1 //
  .mobile_form_container {
    display: block !important;
    min-width: $desktopFormContainerMaxWidth;
    max-width: $desktopFormContainerMaxWidth;

    .teleplan-form-header-info-block {
      position: sticky;
      top: 45px;
      padding-top: 10px;
      background: #fff;
      z-index: 2;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1310px) {
  .form_root_container {
    padding: 0px 15px !important;
  }

  // STEP 1 //
  .desktop_form_container {
    max-width: $desktopFormContainerTabletWidth !important;
    min-width: $desktopFormContainerTabletWidth !important;
  }

  .desktop_catalog_container {
    width: calc(100% - #{$desktopFormContainerTabletWidth}) !important;
  }
}

// Tablets and mobile
@media screen and (max-width: 1023px) {
  .codePickerDesktopWrap {
    display: none;
  }

  .codePickerMobileWrap {
    display: block;
    position: relative;

    .p-inputtext {
      padding-right: 50px;
    }

    i {
      right: 3rem !important;
    }

    button {
      position: absolute;
      width: 42px !important;
      right: 0px;
      height: 40px;
      z-index: 1;
    }
  }

  // FORM //
  .form_root_container {
    padding: 0px 5px !important;
  }

  .desktop_form_container {
    display: none !important;
  }

  .mobile_form_container {
    display: block;
    // max-width: 400px;

    .p-card .p-card-content {
      padding-bottom: 0;
    }
  }

  .formWithCataogsStickyActionButtons {
    position: sticky;
    bottom: 0;
    background: #fff;
    padding: 15px 0px;
    z-index: 1;
    @include gapForActionButtons;
  }

  // ============== //

  // CATALOGS //
  .desktop_catalog_container {
    display: none;
  }

  .toggleCatalogsDesktopButton {
    display: none !important;
  }

  .toggleCatalogsMobileButton {
    display: block !important;
  }

  .catalogsHeaderInfoWrap {
    height: $catalogsHeaderInfoHeight;
  }

  .catalogsBreadcrumbDesktop {
    border-top: unset;
  }

  .searchInputWrapActive {
    position: absolute;
    opacity: 1;
    right: 60px;
    left: 60px;
    width: 100%;
    padding: 10px 115px 10px 0px;
    transition: all 0.3s;

    span {
      width: 100% !important;
      max-height: 35px !important;

      input {
        width: 100% !important;
        max-height: 32px !important;
      }
    }
  }

  .searchInputWrapHidden {
    position: absolute;
    opacity: 0;
    right: 0;
    width: 0;
    transition: all 0.3s;

    span {
      width: 100% !important;
      max-height: 32px !important;

      input {
        width: 100% !important;
        max-height: 32px !important;
      }
    }
  }

  .headerLableRoot {
    position: relative;
  }

  // ============== //
}

// Quick pick tablet and mobile styles
@media (min-width: 350px) and (max-width: 1230px) {
  .quickPickBlockWrap {
    display: block !important;
  }
}
