@import "../../../styles/stylesVariables.scss";

%btnWidth {
    width: $resetButtonWidth !important;
    min-width: $resetButtonWidth !important;
}

.clearButton {
    max-height: unset !important;
    border-top-left-radius: unset;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border: 1px solid transparent !important;
    background: #fff no-repeat !important;
    background-size: 0 2px, 100% 1px !important;
    background-position: 50% 100%, 50% 100% !important;
    background-origin: border-box !important;
    @extend %btnWidth;
}

.blankClearButton {
    @extend %btnWidth;
}