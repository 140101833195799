.rootWrap {
    position: relative;
}

.actionButtonsWrap {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
}

.sidebar {
    width: 20% !important;
}

@media screen and (max-width: 1400px) {
    .sidebar {
        width: 30% !important;
    }
}

@media screen and (max-width: 1023px) {
    .sidebar {
        width: 45% !important;
    }
}

@media screen and (max-width: 767px) {
    .sidebar {
        width: 80% !important;
    }
}