@import '../../../../styles/stylesVariables.scss';

.contentContainer {
  width: $commonTableContainerWidth;
  max-width: $commonTableContainerMaxWidth !important;
  min-width: $commonTableContainerMinWidth !important;
}

.contentContainerInCard {
  width: 100%;
  max-width: $commonTableContainerMaxWidth !important;
  min-width: $commonTableContainerMinWidth !important;
}