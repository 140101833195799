.receivePaymentCard {
    width: 450px;
}

.subtitleName {
    width: 65px;
}

.feePaidInput {
    position: relative;
}

.paidAndFullButton {
    position: absolute !important;
    width: fit-content !important;
    right: 17px !important;
    height: 51px !important;
}

.currenciesWrap {
    width: 120px;
}