/* Add your variable customizations of layout here */
$success: #689F38;
$success_secondary: #7ccb37;
$info: #2196F3;
$infoDark: #123fd3;
$warning: #FBC02D;
$help: #9C27B0;
$danger: #D32F2F;
$gray: #a0a0a0;
$hoverGray: #f5f5f5;
$customMarkerDisabled: #a0a0a0;
$borderGray: var(--surface-d);

//NEGATIVE MARGINS

.p-m--1 {
  margin: -0.25rem !important;
}

.p-m--2 {
  margin: -0.5rem !important;
}

.p-m--3 {
  margin: -1rem !important;
}

.p-m--4 {
  margin: -1.5rem !important;
}

.p-m--5 {
  margin: -2rem !important;
}

.p-m--6 {
  margin: -3rem !important;
}

//top
.p-mt--1 {
  margin-top: -0.25rem !important;
}

.p-mt--2 {
  margin-top: -0.5rem !important;
}

.p-mt--3 {
  margin-top: -1rem !important;
}

.p-mt--4 {
  margin-top: -1.5rem !important;
}

.p-mt--5 {
  margin-top: -2rem !important;
}

.p-mt--6 {
  margin-top: -3rem !important;
}

//bottom
.p-mb--1 {
  margin-bottom: -0.25rem !important;
}

.p-mb--2 {
  margin-bottom: -0.5rem !important;
}

.p-mb--3 {
  margin-bottom: -1rem !important;
}

.p-mb--4 {
  margin-bottom: -1.5rem !important;
}

.p-mb--5 {
  margin-bottom: -2rem !important;
}

.p-mb--6 {
  margin-bottom: -3rem !important;
}

//left
.p-ml--1 {
  margin-left: -0.25rem !important;
}

.p-ml--2 {
  margin-left: -0.5rem !important;
}

.p-ml--3 {
  margin-left: -1rem !important;
}

.p-ml--4 {
  margin-left: -1.5rem !important;
}

.p-ml--5 {
  margin-left: -2rem !important;
}

.p-ml--6 {
  margin-left: -3rem !important;
}

//right
.p-mr--1 {
  margin-right: -0.25rem !important;
}

.p-mr--2 {
  margin-right: -0.5rem !important;
}

.p-mr--3 {
  margin-right: -1rem !important;
}

.p-mr--4 {
  margin-right: -1.5rem !important;
}

.p-mr--5 {
  margin-right: -2rem !important;
}

.p-mr--6 {
  margin-right: -3rem !important;
}

.p-mb-7 {
  margin-bottom: 3.5em !important;
}

.p-mb-8 {
  margin-bottom: 4em !important;
}

.p-mt-7 {
  margin-bottom: 3.5em !important;
}

.p-mt-8 {
  margin-bottom: 4em !important;
}

.p-ml-7 {
  margin-left: 3.5em !important;
}

.p-ml-8 {
  margin-left: 4em !important;
}

.p-mr-7 {
  margin-left: 3.5em !important;
}

.p-mr-8 {
  margin-left: 4em !important;
}