@import '../../../../styles/stylesVariables';
@import '../../../../../public/assets/sass/overrides/layout_variables';

$catalogTabsHeight: 36px;
$catalogPatientChipsHeight: 40px;
$emergencyActiveButtonTextColor: var(--primary-color);
$emergencyButtonTextColor: rgba(0, 0, 0, 0.87);
$emergencyButtonBorder: 1px solid rgba(0, 0, 0, 0.12);
$emergencyButtonActiveBackground: #e0e0e1;
$emergencyButtonInctiveBackground: #fff;

%catalogsTimePickerSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 750px;
  column-gap: 30px;
}

.batch_catalogs_dialog {
  .p-dialog-content {
    padding-top: 1.25rem;
  }
}

.batch-table-drag {
  svg {
    width: 20px;
    height: 60px;
  }
}

.multiple_dates_text {
  color: $info;
}

.practitioner_section_panel {
  .p-panel-header {
    align-items: flex-start;
  }
}

.first_visit_eligibility_message {
  .p-message {
    margin-top: 0;
  }
}

.emergency_buttonset {
  button {
    width: inherit;
    height: 39px;
    margin-top: 1px;
  }

  .emergency_active_button {
    color: $emergencyActiveButtonTextColor;
    border: $emergencyButtonBorder;
  }

  .emergency_inactive_button {
    color: $emergencyButtonTextColor;
    border: $emergencyButtonBorder;
  }

  .emergency_active_button {
    background: $emergencyButtonActiveBackground;
  }

  .emergency_inactive_button {
    background: $emergencyButtonInctiveBackground;
  }

  .emergency_active_button:active,
  .emergency_active_button:focus {
    background: $emergencyButtonActiveBackground;
    color: $emergencyActiveButtonTextColor;
    border: $emergencyButtonBorder;
  }

  .emergency_inactive_button:active,
  .emergency_inactive_button:focus {
    background: $emergencyButtonInctiveBackground;
    color: $emergencyButtonTextColor;
    border: $emergencyButtonBorder;
  }

  .emergency_active_button:hover {
    background: $emergencyButtonActiveBackground !important;
    color: $emergencyActiveButtonTextColor !important;
    border: $emergencyButtonBorder !important;
  }

  .emergency_inactive_button:hover {
    background: $emergencyButtonActiveBackground !important;
    color: $emergencyButtonTextColor !important;
    border: $emergencyButtonBorder !important;
  }
}

.teleplan_group_code_picker {
  ul {
    .p-autocomplete-input-token {
      width: 0px;
    }
  }
}

.createGroupTemplateDialog {
  .p-dialog-content {
    padding-bottom: 0;
  }
}

.group_stepper {
  width: inherit;
}

.codePickerWrap_referral {
  display: block;
  position: relative;

  .p-inputtext {
    padding-right: 50px;
  }

  svg {
    right: 3rem !important;
  }

  button {
    position: absolute;
    width: 42px !important;
    right: 0px;
    height: 40px;
    z-index: 1;
  }
}

.codePickerWrap_referral_group {
  display: block;
  position: relative;

  .p-inputtext {
    padding-right: 90px;
  }

  i {
    right: 7rem !important;
  }

  // Add new practitioner button
  #addNewPractitioner {
    position: absolute;
    width: 42px !important;
    right: 42px;
    height: 40px;
    z-index: 1;
  }
}

.teleplan_two_inputs_in_a_row {
  display: flex;
  gap: 10px;
}

.referred-datatable-responsive-layout .p-datatable-responsive-layout .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

// DESKTOP CATALOGS //

.catalog_tab_view_pos {
  height: calc(100% - #{$catalogTabsHeight} - #{$catalogPatientChipsHeight});

  .p-tabview-panels {
    height: 100%;
  }
}

.catalog_tab_view_group {
  height: calc(100% - #{$catalogTabsHeight});

  .p-tabview-panels {
    height: 100%;
  }
}

.teleplan_catalogs_time_picker_section {
  @extend %catalogsTimePickerSection;

  .teleplan_catalogs_outOfOfficeHoursPremiums {
    max-width: 390px;
    padding-top: 20px;
    min-height: 60px;
  }

  .teleplan_catalogs_time_inputs {
    max-width: 135px;
  }
}

.teleplan_catalogs_emergency_section {
  display: flex;
  column-gap: 45px;
}

.phn_birthday_inputs_wrap {
  display: flex;
  gap: 10px;
}

// ============== //

// STEP 2 //
.teleplan_records_data_table {
  .p-rowgroup-header {
    z-index: 2 !important;
  }

  .p-datatable-tfoot {
    z-index: 3 !important;
  }

  .p-datatable-row-expansion {
    td {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
  }

  .records_table_header {
    display: flex;
    align-items: center;

    .expand_all_columns_button {
      display: block;
      width: 3.5em;
    }

    .label_wrap {
      margin-right: 10px;
      padding-bottom: 0px;
    }
  }
}

.teleplan_records_card {
  width: 100%;
  max-width: 1400px;

  .p-card-content {
    padding-bottom: 0;
  }

  .patientsListOverlay {
    width: 240px;
  }

  .teleplan_records_editor {
    width: 70px;
  }

  .teleplan_table_footer {
    border: none !important;
  }

  .recordsDataTableActionButtons {
    min-width: 66px;
  }

  .teleplan_records_data_table_wrap {
    .teleplan_records_data_table {
      .p-datatable-header {
        padding-left: 10px;
        padding-right: 5px;
      }

      .p-datatable-emptymessage {
        td {
          padding-left: 65px;
        }
      }

      .recordsDxCodeEditor {
        position: relative;
        width: 260px;
      }

      .rowExpansionTemplate {
        // margin-left: 3.5em;

        .p-inplace-content {
          display: flex;
          width: 100%;
        }

        .p-inplace-display {
          padding: 0 !important;
        }
      }

      .p-datatable-row-expansion,
      .p-datatable-emptymessage {
        td {
          width: 100% !important;
        }
      }
    }
  }
}

.teleplan_group_picker_card {
  width: 100%;
  max-width: 1605px;
  height: calc(100vh - #{$mainHeader} - 40px);

  .applyToAllButton {
    width: 120px;
    height: 30px;
  }

  .disabled_autocomplete {
    li {
      font-weight: bolder !important;

      span {
        color: black !important;
      }
    }
  }

  .p-card-content {
    padding: 0px !important;
  }

  .group_picker_data_table_wrap {
    height: calc(100vh - 215px);

    .group_picker_data_table {
      padding-top: 15px !important;

      .p-datatable-header {
        background: transparent !important;
        height: 70px !important;
      }

      .p-datatable-tbody {
        tr {
          // height: 80px !important;
        }
      }
    }
  }
}

// ============== //

// STEP 3 //
.teleplan_preview_card {
  width: 680px;

  .p-card-content {
    padding-bottom: 0;
  }

  .mobileCodesTitle {
    min-width: 50px !important;
  }

  .locationTooltip {
    min-width: 250px !important;
  }
}

.teleplan_group_preview_card {
  height: calc(100vh - #{$mainHeader} - 30px);

  .teleplan_group_review_patients_info_section {
    height: calc(100vh - 470px);
  }
}

.codeDescriptionValue {
  min-width: 55px !important;
}

.teleplan_preview_action_button {
  width: inherit !important;
}

// ============== //

// Mobile only in portrait mode and low screen resolution
@media (min-width: 350px) and (max-width: 388px) {
  // STEP 2 //
  .recordsDxCodeEditor {
    position: unset !important;
    width: 200px !important;
  }

  .teleplan_records_editor {
    width: 200px !important;
  }

  // ============== //
}

// Mobile only in portrait mode
@media (min-width: 389px) and (max-width: 639px) {
}

// Mobile in landscape mode
@media (min-width: 350px) and (max-width: 959px) {
  // STEP 2 //
  .teleplanRecordsStickyActionButtons {
    button {
      width: 300px !important;
    }
  }

  .teleplan_records_data_table {
    .records_table_header {
      display: flex;
      flex-direction: column;
      align-items: start !important;

      .expand_all_columns_button {
        display: none !important;
      }

      .label_wrap {
        margin-right: 0px !important;
        padding-bottom: 5px !important;
      }
    }
  }

  .teleplan_records_data_table_wrap {
    .rowExpansionTemplate {
      margin-left: 0 !important;
    }

    .teleplan_records_data_table {
      .recordsDxCodeEditor {
        position: unset !important;
        width: 260px;
      }

      .teleplan_records_editor {
        width: 260px;
      }
    }

    .p-frozen-column {
      justify-content: end !important;
    }
  }

  // ============== //
}

// Tablets only
@media (min-width: 640px) and (max-width: 1023px) {
}

// Tablets and mobile
@media screen and (max-width: 1023px) {
  .codePickerWrap_referral {
    display: block;
    position: relative;

    .p-inputtext {
      padding-right: 90px;
    }

    svg {
      right: 7rem !important;
    }

    // Add new practitioner button
    #addNewPractitioner {
      position: absolute;
      width: 42px !important;
      right: 42px;
      height: 40px;
      z-index: 1;
    }
  }

  // CATALOGS //
  .referred-datatable-responsive-layout .p-datatable.p-datatable-responsive-layout .p-datatable-thead > tr > th,
  .referred-datatable-responsive-layout .p-datatable.p-datatable-responsive-layout .p-datatable-tfoot > tr > td {
    display: none !important;
  }

  .referred-datatable-responsive-layout .p-datatable.p-datatable-responsive-layout .p-datatable-tbody > tr > td {
    text-align: left;
    display: block;
    width: 100%;
    float: left;
    clear: left;
    border: 0 none;
    padding: 10px 0;
  }

  .referred-datatable-responsive-layout .p-datatable.p-datatable-responsive-layout .p-datatable-tbody > tr > td .p-column-title {
    padding: 0.4rem;
    min-width: 100px;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4rem;
    font-weight: bold;
  }

  .referred-datatable-responsive-layout .p-datatable.p-datatable-responsive-layout .p-datatable-tbody > tr > td:last-child {
    border-bottom: 1px solid var(--surface-d);
  }

  // ============== //
}

@media screen and (max-width: 1540px) {
  .teleplan_catalogs_time_picker_section {
    flex-direction: column;
    @extend %catalogsTimePickerSection;

    .teleplan_catalogs_outOfOfficeHoursPremiums {
      width: 100%;
      max-width: inherit;
      padding-top: 0px;
    }

    .teleplan_catalogs_time_inputs {
      width: 100%;
      max-width: inherit;
    }
  }
}

@media screen and (max-width: 1310px) {
  .phn_birthday_inputs_wrap {
    flex-direction: column !important;
    gap: 0px !important;
  }
}
