// search input google styling
%inputRoot {
  height: inherit;
  position: relative !important;
  width: 100%;
}

%clearIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

%questionIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

%searchbar {
  width: 100%;
  height: 45px;
  padding-left: 45px;
  background: none !important;
  border: 1px solid lightgray !important;
  border-radius: 30px !important;
}

%clearIconWrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  height: 51px;
  width: 40px;
  cursor: pointer;
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

%questionIconWrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  height: 51px;
  width: 40px;
  cursor: pointer;
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

.inputRootMobile {
  @extend %inputRoot;

  i {
    margin-right: 40px !important;
  }
}

.inputRootDesktop {
  @extend %inputRoot;

  i {
    margin-right: 30px !important;
  }
}

.searchbarInput:hover {
  box-shadow: 1px 1px 8px 1px #dcdcdc !important;
}

.searchbarInput:focus {
  box-shadow: 1px 1px 8px 1px #dcdcdc !important;
  outline: none !important;
}

.searchbarDesktop {
  i {
    right: 246px !important;
  }
}

.searchIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  z-index: 1;
}

.questionIconDesktop {
  @extend %questionIcon;
  right: -10px;
}

.questionIconMobile {
  @extend %questionIcon;
  right: -20px;
}

.questionIconWrapMobile {
  @extend %questionIconWrap;
  right: 0px;
}

.questionIconWrapDesktop {
  @extend %questionIconWrap;
  right: 225px;
}

.clearIconMobile {
  @extend %clearIcon;
}

.clearIconDesktop {
  @extend %clearIcon;
  right: -10px;
}

.clearIconWrapMobile {
  @extend %clearIconWrap;
  right: 5px;
  width: 20px;
}

.clearIconWrapDesktop {
  @extend %clearIconWrap;
  right: 255px;
}

.searchbarInputMobile {
  @extend %searchbar;
  padding-right: 35px;
}

.searchbarInputDesktop {
  @extend %searchbar;
  padding-right: 300px;
}

.clearIconSplitter {
  position: absolute;
  right: 227px;
  top: 50%;
  transform: translateY(-50%);
  border-left: 1px solid #dadce0;
  height: 65%;
}

.searchInputOptionsButtons {
  position: absolute;
  top: 8px;
  right: 15px;

  span {
    text-decoration: none !important;
  }
}

.searchOptionButtonInactive {
  color: unset !important;
}
