%selection_card {
  padding: 1rem;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  overflow: hidden;
  vertical-align: bottom;
  cursor: pointer;
}

.selection_card_active {
  @extend %selection_card;
  background-color: var(--primary-color);
  color: var(--primary-color-text);

  button {
    color: #fff !important;
  }
}

.selection_card_inactive {
  @extend %selection_card;
  color: var(--primary-color);
  box-shadow: inset 0 0 0 1px;
}