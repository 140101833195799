$mainHeader: 45px; // should be equal with mainHeaderHeight in utils/defaultLayoutMode
$mainHeaderMobile: 45px; // should be equal with mainHeaderHeight in utils/defaultLayoutMode
$subMenu: 42px;
$mainSearchPanel: 42px;
$chipsPanel: 49px;
$filtersSidebarWidth: 35%;
$mobileFiltersSidebarWidth: 100%;
$filtersSidebarFooterHeight: 40px;
$mobileLandscapeAutocompletePanelHeight: 200px;
$tabletLandscapeAutocompletePanelHeight: 420px;
$resetButtonWidth: 37px;
$removeFiltersForeMoreRecordsHeight: 25px;
$patientDetailsHeaderHeight: 85px;

// Main Sidebar Menu
$mainSidebarMenuOverlayWidth: 60px;
$mainSidebarMenuStaticWidth: 240px;

// Fixed bottom action buttons
$fixedActionButtonsHeight: 42px;

//text styles
$bold500: 600;

//bacground color
$backgroundColor: #f1f3f5;
$borderColorGray: #ebebeb;

//Private invoice card
$privateInvoiceCardWidth: 1100px;
$mobileOnlyPrivateInvoiceCardWidth: 768px;
$mobileOnlyPrivateInvoiceActionButtonsHeight: 65px;

// Common data table
$commonTableContainerWidth: 1500px;
$commonTableContainerMaxWidth: 1500px;
$commonTableContainerMinWidth: 305px;
$customDataTableBorderColor: #e4e4e4;

// Scrollbar
$scrollbarTrackColor: transparent;
$scrollbarThumbColor: #e6e6e6;
$scrollbarThumbHoverColor: #c1c1c1;

// Content
$contentOutsidePaddings-X: 10px;
$contentOutsidePaddings-Y: 20px;
$desktopFormContainerMaxWidth: 525px;

// WSBCR form 8\11 colored text
$wsbcrViewColoredText: rgb(33, 33, 186);