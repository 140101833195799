@import "../../../../styles/stylesVariables.scss";

.rootWrap {
    padding-left: 7.2%;
}

.rootWrapMobile {
    padding-left: 1%;
}

.addressWrap {
    min-width: 260px;
}

.typeWrap {
    cursor: pointer;
}

.contactInfoSeparator {
    border-bottom: 1px solid $borderColorGray
}

.eligibilityRoot {
    width: 310px !important;
}

.eligibilityRootMobile {
    width: fit-content;
}