@import "./stylesVariables.scss";

.mainHeader {
    height: $mainHeader !important;
}

.logo {
    height: 32px !important;
}

.icons {
    font-size: 1.7em !important;
    margin-top: 2px;
}

.fadeDownMobile {
    // top: $mainHeader !important;
}

.fadeDownTablet {
    // top: calc(#{$mainHeader} + 10px) !important;
}

.togglemenuArrow {
    color: #fff !important;
    width: auto !important;
}

@media screen and (max-width: 1024px) {
    .togglemenuArrow {
        display: none !important;
    }
  }