@import "./stylesVariables.scss";

// Add animation for fixed element when main left sidebar menu open-close
%overlayRoot {
  width: calc(100% - #{$mainSidebarMenuOverlayWidth}) !important;
  transition: width 0.2s !important;
  right: 0px !important;
}

%staticRoot {
  width: calc(100% - #{$mainSidebarMenuStaticWidth}) !important;
  transition: width 0.2s !important;
  right: 0px !important;
}