* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 14px;
}

body {
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  min-height: 100%;
  background-color: var(--surface-ground);
}

.privateInvoicePrint {
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 14px;

  // INVOICE
  .invoice {
    padding: 2rem;

    @mixin invoiceTo ($borderWeight, $borderColor) {
      margin-top: 1.5rem;
      padding-top: 2rem;
      border-top: $borderWeight solid $borderColor;

      .bill-to {
        font-size: 1.25rem;
        font-weight: 700;
        margin-bottom: .5rem;
      }

      .invoice-to-info {
        div {
          margin-bottom: .5rem;
        }
      }
    }

    @mixin invoiceItems ($borderWeight, $borderColor) {
      margin-top: 2rem;
      padding-top: 2rem;

      table {
        width: 100%;
        border-collapse: collapse;

        tr {
          border-bottom: $borderWeight solid $borderColor;
        }

        th {
          font-weight: 700;
        }

        th,
        td {
          padding: 1rem;
          text-align: right;
        }

        th:first-child,
        td:first-child {
          text-align: left;
        }
      }
    }

    .invoice-header {
      display: flex;
      justify-content: space-between;
    }

    .invoice-company {
      .logo-image {
        width: 120px;
        margin-bottom: .5rem;
      }

      div {
        margin-bottom: .5rem;
      }

      .company-name {
        font-weight: 700;
        font-size: 1.5rem;
      }
    }

    .invoice-title {
      font-size: 2rem;
      margin-bottom: 2rem;
      text-align: right;
    }

    .invoice-details {
      width: 18rem !important;
      display: flex;
      flex-wrap: wrap;

      >div {
        width: 50%;
        margin-bottom: .5rem;
      }

      .invoice-label {
        text-align: left;
        font-weight: 700;
      }

      .invoice-value {
        text-align: right;
      }
    }

    .invoice-to {
      @include invoiceTo(3px, black);
      margin-top: 1.5rem;
    }

    .invoice-to-email {
      @include invoiceTo(1px, rgba(0, 0, 0, 0.12));
      margin-top: 1.5rem;
    }

    .invoice-items {
      @include invoiceItems(2px, black);
    }

    .invoice-items-email {
      @include invoiceItems(1px, rgba(0, 0, 0, 0.12));
    }

    .invoice-summary {
      display: flex;
      justify-content: space-between;
      margin-top: 2.5rem;
      padding-top: 2.5rem;

      .invoice-value {
        font-weight: 700;
      }
    }
  }


  // DATA TABLE
  %tableBorders {
    border-right: 1px solid transparent !important;
    border-left: 1px solid transparent !important;
  }

  .privateInvoiceCardPlainCustomDataTable {
    border-collapse: collapse;
    width: 100%;

    td {
      padding: 0.25rem 0.5rem;
      border: 1px solid #e4e4e4;
    }

    .captionPrint {
      @extend %tableBorders;
    }

    .p-datatable-row-expansion {
      td {
        @extend %tableBorders;
      }
    }
  }
}



// COMMON
.contentRootWrap {
  width: 1000px;
}

.addressWrap {
  width: 200px;
}

.invoiceSectionWrap {
  width: 18rem !important;

  .serviceDate {
    width: 185px;
  }
}

.invoiceCardRoot {
  width: 1100px !important;
}