%alphabetLetter {
    padding: 0.3em 0.5em;
    max-width: 1em;
    font-weight: bold;
    cursor: pointer;
}

.root {
    overflow-x: scroll;
    overflow-y: hidden;
}

.lettersWrap {
    white-space: nowrap;
    margin-left: 0.5em;
    .letters {
        display: inline-block;
        margin-right: 1em;
        @extend %alphabetLetter;
    }
    .selectedLetter {
        @extend %alphabetLetter;
        border: 1px solid #c0c0c0;
        background-color: #ffffff;
    }
}