.descktopFixedActionButtons {
    position: sticky;
    bottom: 0;
    background: #fff;
    padding-bottom: 10px;
    z-index: 99;
}

.splitButtonRoot {
    min-width: 175px !important;
}

.nextButtonDesctop {
    width: 111px !important;
}