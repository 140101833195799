@import "../../../../../../public/assets/sass/overrides/layout_variables";

.chipsMobile {
    overflow: auto;

    li {
        width: max-content !important;
    }
}

.desktopCatalogSlideMenuWrap {
    min-width: 17rem;
}

//dataScroller
.dataScrollerWrap {
    width: 100%;
}

.dataScrollerCodeColumn {
    width: 65px !important;
    min-height: 45px !important;
}

.dataScrollerAmountColumn {
    width: 80px !important;
    min-height: 45px !important;
}

.dataScrollerLableColumn {
    width: 100% !important;
    min-height: 45px !important;
    word-wrap: break-word;
    word-break: break-all;
}

.dataScrollerFooterBackIcon {
    font-size: 20px;
}

.dataScrollerItemRoot {
    background: $hoverGray;
}

//================================//


//content sliding animation
.mobileCatalogsContentRoot {
    position: relative;
    width: 100%;
}

//slideMenu
.showMobileSlideMenu {
    width: 100%;
    // opacity: 1;
    transition: all 0.4s;
}

.hideMobileSlidemenu {
    width: 100%;
    // opacity: 0;
    padding-right: 30px;
    margin-left: -100%;
    transition: all 0.4s;
}

//dataScroller
.showMobileDataScroller {
    width: 100%;
    transition: all 0.4s;
}

.hideMobileDataScroller {
    width: 100%;
    // opacity: 0;
    padding-left: 30px;
    margin-right: -100%;
    transition: all 0.4s;
}

.showDataScrollertableFooter {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    transition: all 0.4s;
}

.hideDataScrollertableFooter {
    // position: fixed;
    // bottom: 0;
    // left: 15px;
    background: #fff;
    transition: all 0.4s;
}

//================================//

.highlightedCode {
    color: var(--primary-color);
}