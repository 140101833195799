@import '../../../../styles/stylesVariables.scss';

.wsbcReportPrint {
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 14px;

  .demographicsInfoTableHeaderPrint {
    padding: 0.25rem 0.5rem;
    border-top: 1px solid $customDataTableBorderColor;
    border-left: 1px solid $customDataTableBorderColor;
    border-right: 1px solid $customDataTableBorderColor;
    background: #f8f9fa;
  }

  .wordBreakTextPrint {
    word-break: break-all
  }

  .demographicsInfoTablePrint {
    border-collapse: collapse;
    width: 100%;

    td {
      padding: 0.25rem 0.5rem;
      border: 1px solid $customDataTableBorderColor;
    }

    .captionPrint {
      border-right: 1px solid transparent !important;
    }
  }

  .demographicInfoRowLabelPrint {
    width: 55%;
  }

  .demographicInfoRowValuePrint {
    display: flex;
    justify-content: start;
  }

  .coloredText {
    color: $wsbcrViewColoredText;
  }
}