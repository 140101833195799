.safe-html-content {
  word-wrap: break-word;
  overflow-wrap: anywhere; // Helps break long words or URLs
  text-align: left;

  img {
    max-width: 100%; // Limit the width to the container's width
    max-height: 200px; // Set a maximum height for the images
    height: auto; // Maintain aspect ratio
    object-fit: contain; // Ensure the image is contained within the specified dimensions
    cursor: pointer; // Change the cursor to a pointer when hovering over the image
  }
}