.status-badge-wrap {

  // Accepted/Paid
  &.status-2 {
    background: #C8E6C9;
    color: #256029;
  }

  //Rebilled
  &.status-21 {
    background: #abdf7c;
    color: #337537;
  }

  // Declined/Not Paid/Error
  &.status-3,
  &.status-6,
  &.status-10,
  &.status-17 {
    background: #FFCDD2;
    color: #C63737;
  }

  // Outstanding
  &.status-0 {
    background: #FEEDAF;
    color: #8A5340;
  }

  // In Process
  &.status-1,
  &.status-201 {
    background: #B3E5FC;
    color: #23547B;
  }

  //In Process (Debit)
  &.status-203 {
    background: #52c8ff;
    color: #164e7b;
  }

  // Drafts
  &.status-4 {
    background: #ECCFFF;
    color: #694382;
  }

  // Archived
  &.status--1,
  &.status--3,
  &.status--4,
  &.status-9,
  &.status-20 {
    background: #d5d4d4;
    color: #4b4b4b;
  }
}