@import "../../../../styles/stylesVariables.scss";
@import "../../../../styles/layoutVariables.scss";

.rootDesktop {
    min-height: calc(100vh - 130px);
}

.rootMobile {
    min-height: calc(100vh - 45px);
}

%header {
    position: fixed;
    top: $mainHeader;
    z-index: 999;
    height: $patientDetailsHeaderHeight;
}

.headerRoot {
    @extend %header;
    left: 0px;
    width: 100%;
    background: #fff;
}

.headerOverlayRoot {
    @extend %header;
    @extend %overlayRoot;
}

.headerStaticRoot {
    @extend %header;
    @extend %staticRoot;
}

.patientsNameRoot {
    max-height: 15px;
}

.iconContainer {
    max-width: 30px;
}

.menubarRoot {
    margin-top: $patientDetailsHeaderHeight;
    // border: none
}

.profileContentMobile {
    padding-top: $patientDetailsHeaderHeight;
}

.root_5 {
    padding-top: 5em;
}

.root_6 {
    padding-top: 6em;
}

.closeButton {
    width: 100%;
    height: inherit;
}

.viewMoreButton {
    width: max-content !important;
}

.editNameButton {
    margin-top: -13px !important;
}

.typeWrap {
    cursor: pointer;
}

.checkMSPButton {
    width: fit-content !important;
}

.navMenuIcon {
    margin-right: 10px !important;
}

.tabContent {
    position: relative;
}

.profileContentRoot {
    position: relative;
}