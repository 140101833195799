@import '../../../../../public/assets/sass/overrides/layout_variables';

.noteButton {
    margin-left: -13px !important;
    width: max-content !important;
}

.mspEligibilityStatusIcon {
    margin-top: 1px;

    &_success {
        color: $success  !important
    }

    &_disabled {
        color: $gray  !important
    }
}

.largeIcon {
    font-size: 1.7em !important;
}

.reportRowItemWrap {
    min-width: 60px;
}

.thumbIconWrap {
    min-width: 40px !important;
}