@import "../../../../styles/stylesVariables.scss";

.root {
    height: calc(100vh - #{$mainHeader}) !important;
}

.rootInput {
    width: 100% !important;
}

.servicesRecentCodesWrap {
    height: 66%;
}

.contentWrap {
    margin-bottom: 30px;
}

.actionButtonsRoot {
    position: sticky;
    bottom: 0;
    background: #fff;
    z-index: 99;
}

.rootContainer {
    width: 1500px !important;
}

@media screen and (max-width: 1500px) {
    .rootContainer {
        width: 100% !important;
    }
}


$searchInputHeight: 35px;

.headerLableRoot {
    position: relative;
}

.desktopSearchInput {
    input {
        width: 100% !important;
        max-height: $searchInputHeight  !important;
    }
}

.searchInputWrapActive {
    position: absolute;
    opacity: 1;
    right: 0;
    width: 100%;
    padding: 10px 0px;
    transition: all 0.3s;

    span {
        width: 100% !important;
        max-height: $searchInputHeight  !important;

        input {
            width: 100% !important;
            max-height: $searchInputHeight  !important;
        }
    }
}

.searchInputWrapHidden {
    position: absolute;
    opacity: 0;
    right: 0;
    width: 0;
    transition: all 0.3s;

    span {
        width: 100% !important;
        max-height: 35px !important;

        input {
            width: 100% !important;
            max-height: 35px !important;
        }
    }
}