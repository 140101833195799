.paginatorRoot {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
}

.borderBottomLine {
    border-bottom: 1px solid lightgray;
}

.sequenceRoot {
    height: 18px;
}

.card {
    width: 800px;
}

.closeButton {
    width: 100% !important;
}

.debitCounter {
    color: gray
}

.iconWrap {
    width: 2.4em;
    height: 2.4em;
    margin-top: 3px;
    z-index: 0;
}

.codeTitle {
    min-width: 55px !important;
}

.addressLine {
    width: 60% !important;
}