.root {
    width: 100% !important;
}

.lableDesktop {
    width: 155px !important;
    min-width: 155px !important;
}

.lableMobile {
    width: 100% !important;
    min-width: 100% !important;
}