.mobileRowExpantionRoot {
    min-height: 105px !important;
}

.leftItemBox {
    min-width: 5rem;
}

.headerDetailsContainer {
    width: inherit !important;
}

.mobileRowExpansionSkeletonRoot {
    height: 68px !important;
}

.mobileRowExpansionSkeletonDotsButtons {
    width: 24px !important;
}