.code_picker_autocomplete_single_code {
  .p-autocomplete-input-token {
    display: none;
  }
}

.code_picker_autocomplete_with_catalogs_button {
  display: block;
  position: relative;

  .p-inputtext {
    padding-right: 65px;
  }

  i {
    display: block;
    right: 3rem !important;
  }

  button {
    position: absolute;
    width: 42px !important;
    top: 0px;
    right: 0px;
    height: 40px;
  }
}

// .code_picker_autocomplete_with_catalogs_button {
//   display: block;

//   i {
//     padding-right: 0px;
//   }

//   button {
//     display: none;
//   }
// }

// @media screen and (max-width: 1023px) {
//   .code_picker_autocomplete_with_catalogs_button {
//     display: block;
//     position: relative;

//     .p-inputtext {
//       padding-right: 65px;
//     }

//     i {
//       display: block;
//       right: 3rem !important;
//     }

//     button {
//       position: absolute;
//       width: 42px !important;
//       top: 0px;
//       right: 0px;
//       height: 40px;
//       z-index: 1;
//     }
//   }
// }