@import "../../styles/stylesVariables.scss";
@import "../../styles/layoutVariables.scss";

%root {
    height: $mainSearchPanel;
    background: #fff;
    z-index: 99;
}

.showOnPatientDetailsScreenRoot {
    height: $mainSearchPanel;
}

.root {
    @extend %root;
    position: sticky;
    top: calc(#{$mainHeader} + #{$subMenu});
}

.rootMobile {
    @extend %root;
    position: fixed;
    top: calc(#{$mainHeaderMobile} + #{$subMenu});
}

.iPadProInLandscapeOverlayRoot {
    @extend %root;
    @extend %overlayRoot;
    position: fixed;
    top: calc(#{$mainHeaderMobile} + #{$subMenu});
}

.iPadProInLandscapeStaticRoot {
    @extend %root;
    @extend %staticRoot;
    position: fixed;
    top: calc(#{$mainHeaderMobile} + #{$subMenu});
}

.searchIcon {
    border-bottom: none !important;
    background: #f1f1f1 !important;
    border: 1px solid transparent !important;
}

.addButton {
    width: 140px;
}

.bageWrap {
    height: inherit !important;
}

.badgeIcon {
    top: 10px !important;
    right: 12px !important;
    min-width: 10px !important;
    height: 18px !important;
    line-height: 18px !important;
}

.splitButton {
    min-width: max-content;
}