@import '../../styles/layoutVariables.scss';
@import '../../styles/stylesVariables.scss';

.actionButtonsColumn {
    max-width: 70px;
    min-width: 70px;
}

.expanderColumn {
    max-width: 70px;
    min-width: 70px;
}

.questionIcontTooltip {
    max-width: 300px !important;
}

.mobileDataTable {
    display: none;
}

.desktopDataTable {
    input {
        height: 40px;
    }
}

.desktopDataTableWrap {
    height: calc(100vh - #{$mainHeader} - 125px)
}

.mobileDataTableWrap {
    display: none;
}

.tableHeaderWrap {
    display: flex;
    align-items: center;
    min-height: 40px;
    margin: 0px -0.5rem !important;
}

%tableOptionsWrap {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
}

.tableOptionsWrap {
    @extend %tableOptionsWrap;
    justify-content: space-between;
}

.tableOptions {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0px 10px;
}

.tableHeaderMobile {
    display: none;
}

.sideBarFiltersChipsWrap {
    display: none;
}

.filtersBadge {
    position: absolute;
    left: 1px;
    top: -4px;
    z-index: 1;
}

.mobileTableOptionsBadge {
    position: absolute;
    left: 35px;
    top: -7px;
    z-index: 1;
}

// TableHeaderDesktop //
.tableHeaderDesktop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 50px;
}

.searchDesktop {
    width: 275px;
}

.addNewButtonDesktop {
    width: content;
}

.dataTableChipsWrap {
    display: flex;
    justify-content: end;
    align-items: center;
}

.chipsScroller {
    right: 100px;
    min-height: 50px;
    overflow-x: auto;
}

// ================= //

@media only screen and (max-width: 768px) {
    .mobileDataTable {
        //  display: flex; // Autoscroll to the highlighted item is not available when data table only is scrollable. Current theme vertion is 7.2.0
        display: block;

        tbody>tr>td {
            padding: 1rem 0.4rem !important;
            flex-direction: column;
        }
    }

    .desktopDataTableWrap {
        display: none;
    }

    .mobileDataTableWrap {
        display: flex;
        height: calc(100vh - 145px)
    }

    .tableOptionsWrap {
        @extend %tableOptionsWrap;
        justify-content: center;
    }

    .tableOptions {
        display: none !important;
    }

    .tableHeaderDesktop {
        display: none;
    }

    .tableHeaderMobile {
        display: flex !important;
        position: relative;
        display: none;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .dataTableChipsWrap {
        display: none;
    }

    .sideBarFiltersChipsWrap {
        display: block;
    }
}