// .notes-list-header-container {
//   padding: 1rem;
//   background-color: #f9f9f9; // Light background for contrast
//   border-bottom: 1px solid #ccc; // Subtle border to separate header from timeline
// }

.notes-list-header-title {
  font-size: 1.5rem; // Make the title larger for emphasis
  margin: 0;
}


