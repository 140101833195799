$menu-width: 240px;

.cash-flow-filters {
  position: sticky;
  top: 60px;
  height: calc(100vh - 75px);
  width: $menu-width;
  min-width: 240px;
  max-width: 240px;
}

.charts-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 15px;
  width: calc(100% - #{$menu-width});
}

@media screen and (max-width: 768px) {
  .cash-flow-filters {
    position: relative;
    top: 0;
    height: auto;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  .charts-wrap {
    width: 100%;
  }
}
