@import "./stylesVariables.scss";

.logoRoot {
  height: $mainHeader !important;
}

.logoRootMobile {
  height: $mainHeaderMobile !important;
}

.logoWrap {
    opacity: 1;
    transition: opacity 0.2s ease-in 0.2s,
}

.logoWrapHidden {
  opacity: 0;
  transition: opacity 0.3s ease-out,
}

.logo {
   height: 37px !important; 
}

.logoMobile {
    height: 30px !important; 
 }

 .mainContentMobile {
  // background: $backgroundColor;
  padding-top: $mainHeaderMobile !important;
}
.mainContent {
  // background: $backgroundColor;
  padding-top: $mainHeader !important;
}