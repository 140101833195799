.patient_input_with_add_button {
  display: block;
  position: relative;

  .p-inputtext {
    padding-right: 100px;
  }

  svg {
    right: 4rem !important;
  }

  button {
    position: absolute;
    width: 42px !important;
    right: 0px;
    top: 0px;
    height: 40px;
    z-index: 1;
  }
}

.patient_picker_autocomplete_single_code {
  .p-autocomplete-input-token {
    display: none;
  }
}