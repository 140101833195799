.catalogsButton {
  position: absolute !important;
  top: 0px !important;
  right: 0px !important;
  width: 35px !important;
  height: 35px !important;
  z-index: 1 !important;
}

.dropdownAutocomplete {
  button {
    right: 42px !important;
  }
}

.root {
  position: relative;

  ul>li>input {
    padding-right: 100px !important;
  }

  i {
    right: 4rem !important;
  }
}