@import './stylesVariables.scss';

@mixin gapForActionButtons {
  row-gap: 10px;
  column-gap: 10px;
}

@mixin scrollbar {
  height: 0.7em;
  width: 0.7em;
}

@mixin scrollbarTrack {
  background: $scrollbarTrackColor;
  border: 0 none;
}

@mixin scrollbarThumb {
  position: relative;
  background: $scrollbarThumbColor;
  border-radius: 3px;
  z-index: 2;
  cursor: pointer;
  opacity: 0;
  -webkit-transition: opacity .25s linear;
  transition: opacity .25s linear;
}

@mixin scrollbarThumbHover {
  background: $scrollbarThumbHoverColor;
}



// FORM WITH CATALOGS //
@mixin form_with_catalogs_scroll_panel {
  width: 100%;
  height: 100%;

  .p-scrollpanel-content {
    padding-right: 10px;
    padding-bottom: 35px !important;
  }

  .p-scrollpanel-wrapper {
    border-right: 12px solid transparent;
  }
}

// ================= //