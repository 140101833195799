@import "../../../../styles/stylesVariables.scss";
@import "../../../../../public/assets/sass/overrides/layout_variables";

.title {
  font-weight: 500;
}

.patientsListOverlay {
  width: 240px;
}

.mobileEditButton {
  width: 10px !important;
  height: 10px !important;
}

.rowValue {
  min-width: 85px !important;
  width: max-content;
}

%sticky {
  position: sticky;
  background: #fff;
  z-index: 999;
}

.mobileStickyHeader {
  @extend %sticky;
  top: $mainHeaderMobile;
}

.desctopStickyHeader {
  @extend %sticky;
  top: $mainHeader;
}

.tableFooter {
  border: none !important;
}

.noClaimsToDisplayButtons {
  width: 100%;
  height: 100%;
}

.customInplace {
  button {
    width: 2.4em !important;
    height: 2.4em !important;
  }
}

.invalidPractitioner {
  color: $danger  !important;
}

.mobileDataTableRoot {
  td {
    padding: 0px !important;
  }
}

// .desktopDataTableRoot {
//   td {
//     max-width: 200px;
//     width: 150px;
//   }
// }

@media screen and (min-width: 768px) {
  .reviewRoot {
    padding: 0px 30px;
  }
}