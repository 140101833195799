@import "../../styles/stylesVariables.scss";

%spinnerWrap {
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

// ------ spinner ------ //
.progressSpinnerWithSidebar {
    @extend %spinnerWrap;
    left: 35%;
    transition: left .3s;
}

.progressSpinnerWrap {
    @extend %spinnerWrap;
    left: 50%;
    transition: left .3s;
}

.progressSpinner {
    width: 70px !important;
    height: 70px !important;
}

// ------ filterby ------ //
.filterByInput {
    display: flex;
    padding-left: 0;
    max-width: 8em;
}

// ---------------------------------------- //

// ------ Mobile table view ------ //
.root {
    border-bottom: 1px solid #e8e8e8;
}

.cm_header {
    min-width: 10em;
    font-weight: 'bold';
    padding: 0.5em;
    color: black;
    line-height: 1.7em;
}

// ---------------------------------------- //

.actionColumn {
    text-align: center;
    outline: none !important;
}

.rowToggleButton {
    min-width: 3.5rem;
}

.noRecordsFound {
    height: calc(100vh - #{$mainSearchPanel}) !important;
    background-color: #fff;
}

.noRecordsFoundWithRemoveBtn {
    height: calc(100vh - #{$mainSearchPanel} - #{$removeFiltersForeMoreRecordsHeight}) !important;
    background-color: #fff;
}

.noRecordsFoundText {
    margin-top: 7rem !important;
}

.RFFMRHeight {
    position: fixed;
    top: calc(#{$mainHeaderMobile} + #{$subMenu}) !important;
    height: $removeFiltersForeMoreRecordsHeight  !important;
    // z-index: 100;
}