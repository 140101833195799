@import "../../styles/stylesVariables.scss";

.rootMarginTopMobile {
    margin-top: calc(#{$subMenu} + #{$mainSearchPanel}) !important;
}

.rootMarginTopMobileWithMoreRecords {
    margin-top: calc(#{$subMenu} + #{$mainSearchPanel}) !important;
}

.rootMarginTopTablet {
    margin-top: calc(#{$subMenu} + #{$mainSearchPanel} + #{$chipsPanel}) !important;
}

.rootMarginBot {
    padding-bottom: 100px !important;
}