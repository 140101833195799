.claim_status_avatar {
  position: relative;
  text-transform: uppercase;
  border-radius: 50%;
  font-weight: 700;
  font-size: 14px;

  .claim_status_avatar_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}