.eClaimForm {

  // STEP 1 //
  .eclaim_step_one_wrap {
    width: 100%;
    max-width: 1600px;
  }

  .policy_skeleton {
    width: 322px !important;
    height: 132px !important;
  }

  // STEP 2 //
  .eclaim_step_two_input_wrap {
    width: 100%;
    max-width: 250px;
  }

  .eclaim_step_two_left_gap {
    width: 100%;
    max-width: 15px;
  }

  .eclaim_step_two_right_gap {
    width: 100%;
    max-width: 77px;
  }
}

.service_dates_list_desktop {
  display: none !important;
}


// Mobile only in portrait mode and low screen resolution
@media (min-width: 350px) and (max-width: 388px) {}

// Mobile only in portrait mode
@media (min-width: 389px) and (max-width: 639px) {}

// Mobile in landscape mode
@media (min-width: 350px) and (max-width: 991px) {
  .eclaim_step_two_input_wrap {
    width: 100%;
    max-width: unset !important;
  }
}

// Tablets only
@media (min-width: 640px) and (max-width: 1023px) {
  .service_dates_list_desktop {
    display: none !important;
  }
}

// Tablets and mobile
@media screen and (max-width: 1023px) {}