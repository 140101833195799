@import '../../../../../../styles/stylesVariables.scss';
@import '../../../../../../../public/assets/sass/overrides/layout_variables';

.preview_content_root {
  position: relative;
}

.preview_root_container {
  width: 920px;
  padding: 15px;
}

.preview_card {
  height: 100%;
  width: 100%;

  .p-card-body {
    height: 100%;
  }

  .p-card-content {
    height: 100%;
    // padding-bottom: 30px;
  }
}

.wsbcReportDataTable {
  thead {
    display: none !important;
  }
}

.card_container {
  position: relative;
  padding: 30px;
  min-width: 770px;
  width: 100%;
}

.actionButtonsWrap {
  max-width: 920px;
  width: 100%;
  height: 47px;
}

.form_type {
  position: absolute;
  top: 250px;
  right: 0px;
}

.demographicsInfoHederDesktop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
}

.demographicsInfoHederMobile {
  display: none !important;
}

.demographicsInfoTableHeader {
  padding: 0.25rem 0.5rem;
  border-top: 1px solid $customDataTableBorderColor;
  border-left: 1px solid $customDataTableBorderColor;
  border-right: 1px solid $customDataTableBorderColor;
  background: #f8f9fa;
}

.wordBreakText {
  word-break: break-all
}

.demographicsInfoTable {
  border-collapse: collapse;
  width: 100%;

  td {
    padding: 0.25rem 0.5rem;
    border: 1px solid $customDataTableBorderColor;
  }

  .caption {
    border-right: 1px solid transparent !important;
  }
}

.wsbcMobileLabel {
  display: none !important;
}

.wsbcDesktopLabel {
  display: block;
}

.demographicInfoRowLabel {
  width: 55%;
}

.demographicInfoRowValue {
  display: flex;
  justify-content: start;
}

.demographicsInfoTableDesktop {
  display: block;
}

.demographicsInfoTableMobile {
  display: none;
}

.priorDesktopLayout {
  display: contents !important;
}

.priorMobileLayout {
  display: none !important;
}

.coloredText {
  color: $wsbcrViewColoredText;
}

@media screen and (max-width: 767px) {
  .wsbcMobileLabel {
    display: block !important;
  }

  .wsbcDesktopLabel {
    display: none !important;
  }

  .card_container {
    position: relative;
    padding: 20px;
    min-width: unset;
  }
}

@media screen and (max-width: 639px) {
  .demographicsInfoHederDesktop {
    display: none !important;
  }

  .demographicsInfoHederMobile {
    display: block !important;
    padding-bottom: 15px;

    .demographicsInfoStatusWrap {
      width: max-content;
      padding-bottom: 10px;
    }
  }

  .demographicInfoRowLabel {
    width: 70%;
  }

  .demographicInfoRowValue {
    display: flex;
    justify-content: end;
  }

  .demographicsInfoTableDesktop {
    display: none;
  }

  .demographicsInfoTableMobile {
    display: block;

    .demographicsTableMobileCaption {
      width: 50%;
    }
  }

  .priorDesktopLayout {
    display: none !important;
  }

  .priorMobileLayout {
    display: block !important;
  }
}