.comments-wrap {
  overflow-y: scroll;
  height: 100%;
  max-height: calc(100vh - 275px);
}

.backButtonWrap {
  max-width: 190px;
  min-width: fit-content;
  width: 100%;
}
.ql-editor {
  font-size: 18px;
}
#newTitle {
  font-size: 16px;
}
@media screen and (max-width: 768px) {
  .comments-wrap {
    height: calc(100vh - 345px);
  }
}

@media screen and (max-width: 576px) {
  .comments-wrap {
    height: calc(100vh - 320px);
  }
}
