.radio_buttons_wrap {
  height: 40px;
}

// Mobile only in portrait mode and low screen resolution
@media (min-width: 350px) and (max-width: 388px) {}

// Mobile only in portrait mode
@media (min-width: 389px) and (max-width: 639px) {}

// Tablets only
@media (min-width: 640px) and (max-width: 1023px) {}

// Tablets and mobile
@media screen and (max-width: 1023px) {}